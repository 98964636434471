// Packages
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion } from "framer-motion"
import HamburgerMenu from "react-hamburger-menu"
import { Link } from "gatsby-link"

// Components
import Button from "@gray-fox-components/components/buttons/button"

// Images
import GrayFoxLogoImage from "@gray-fox-components/images/gray-fox-logo.svg"

// Utils
import { getMediaQueries, colors } from "@gray-fox-components/utils/utils"

// Styles
const { canHover, above, below } = getMediaQueries()
const StyledComponentContainer = styled.div`
  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
  }
`
const StyledContainer = styled.div`
  background-color: var(--white-and-black-white-hex);
  max-width: 1248px;
  padding: 12px 24px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
  a:not(.logo),
  button {
    flex-shrink: 0;
  }
  .logo {
    display: flex;
    width: fit-content;
    img {
      width: 120px;
      ${above.mobile`
        width: 168px;
      `}
      ${above.tablet`
        width: 202px;
      `}
    }
  }
  ${above.mobile`
    padding: 25px 48px;
  `}
  ${above.tablet`
    padding-block: 60px;
    & > a:not(.logo), & > button {
      display: none;
    }
  `}
`
const StyledNavItemsList = styled.ul`
  display: none;
  column-gap: 24px;
  align-items: center;
  justify-content: flex-end;
  a {
    color: var(--primary-colors-dark-navy-blue-hex);
    position: relative;
    font-size: 16px;
    line-height: 25px;
    text-decoration: none;
    padding: 10px;
    transition: 0.2s ease-in-out;
    ::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--accent-sunset-sunset-hex);
      visibility: hidden;
      transform: scaleX(0);
      transition: 0.3s ease-in-out;
    }
    &.active {
      ::before {
        visibility: visible !important;
        transform: scaleX(1) !important;
      }
    }
    ${canHover`
      &:not(.button):hover {
        text-decoration: none;
        ::before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    `}
  }
  ${above.tablet`
    display: flex;
  `}
`
const StyledBurgerButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  padding: 0;
  &.desktop {
    display: none;
  }
  ${below.tablet`
    &.desktop {
      display: initial;
      align-self: center;
    }
    display: initial;
    align-self: center;
  `};
`
const StyledModalBurgerButton = styled(StyledBurgerButton)``
const StyledModalBurgerContainer = styled.div`
  background-color: var(--white-and-black-white-hex);
  display: flex;
  justify-content: center;
  padding-block: 20px;
  width: 100%;
`
const StyledMobileNavContainer = styled(motion.div)`
  background: var(--primary-lights-1-hex);
  border: none;
  width: 100vw;
  min-height: 100vh;
  z-index: 1000;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`
const StyledMobileNav = styled.nav``
const StyledMobileLinkWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  row-gap: 20px;
`
const StyledMobileNavItemsList = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  a {
    position: relative;
    display: block;
    text-align: left;
    color: var(--primary-colors-dark-navy-blue-hex);
    text-decoration: none;
    padding: 10px;
    margin: 0;
    font-size: 20px;
    line-height: 36px;
    transition: 0.2s ease-in-out;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: 0;
      left: 0;
      background-color: var(--primary-colors-sunset-hex);
      visibility: hidden;
      transform: scaleX(0);
      transition: 0.3s ease-in-out;
    }
    ${canHover`
      :hover {
        ::before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    `}
    &.active {
      ::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }
`

// Main Component
function Nav(props) {
  const variants = {
    open: { y: 0 },
    closed: { y: "-100%" },
  }

  const hamburgerMenuProps = {
    isOpen: props.navIsOpen,
    menuClicked: () => props.setNavOpen(!props.navIsOpen),
    animationDuration: 0.3,
    height: 24,
    width: 24,
    color: colors["primary-colors-dark-navy-blue"].hex,
    strokeWidth: 2,
    borderRadius: 2,
    className: "burger-time",
  }

  const mobileNavItems = [
    <Link to="/" key="home" activeClassName="active">
      Home
    </Link>,
    <Link to="/about/" key="about" activeClassName="active">
      About
    </Link>,
    <Link to="/team/" key="team" activeClassName="active">
      Team
    </Link>,
    <Link to="/contact/" key="contact" activeClassName="active">
      Contact
    </Link>,
  ]

  const navItems = [
    ...mobileNavItems,
    props.bankerData ? (
      <Button
        key="apply"
        text="Apply now"
        type="primary"
        linkAttributes={{
          href: props.bankerData.apply || "#",
          target: "_blank",
          rel: "noreferrer",
        }}
      />
    ) : (
      <Button
        key="apply"
        text="Get started"
        type="primary"
        functions={{
          onClick: () => props.setIsApplyModalOpen(true),
        }}
      />
    ),
  ]
  return (
    <StyledComponentContainer>
      {!props.navIsOpen && (
        <>
          <StyledContainer>
            <Link className="logo" to="/">
              <img src={GrayFoxLogoImage} alt="Gray Fox Mortgage" />
            </Link>

            {props.useHamburgerMenu && (
              <StyledBurgerButton
                className="desktop"
                onClick={() => props.setNavOpen(!props.navIsOpen)}
              >
                <HamburgerMenu {...hamburgerMenuProps} />
              </StyledBurgerButton>
            )}
            {navItems[navItems.length - 1]}

            <StyledNavItemsList className="nav-items-list">
              {navItems.map((item, i) => {
                return <li key={`nav-item-${item.key}`}>{item}</li>
              })}
            </StyledNavItemsList>
          </StyledContainer>
        </>
      )}

      {props.navIsOpen && (
        <StyledMobileNavContainer
          initial={{ y: "-100%" }}
          animate={props.navIsOpen ? "open" : "closed"}
          variants={variants}
          transition={{
            default: {
              type: "spring",
              mass: 1.5,
              damping: 15,
              stiffness: 90,
            },
          }}
        >
          {props.useHamburgerMenu && (
            <StyledModalBurgerContainer>
              <StyledModalBurgerButton
                alt="mobile navigation"
                onClick={() => props.setNavOpen(!props.navIsOpen)}
              >
                <HamburgerMenu {...hamburgerMenuProps} />
              </StyledModalBurgerButton>
            </StyledModalBurgerContainer>
          )}
          <StyledMobileLinkWrapper>
            <StyledMobileNav>
              <StyledMobileNavItemsList className="mobile-nav-items-list">
                {mobileNavItems &&
                  mobileNavItems.map((item, i) => {
                    return <li key={`nav-item-${item.key}`}>{item}</li>
                  })}
              </StyledMobileNavItemsList>
            </StyledMobileNav>
          </StyledMobileLinkWrapper>
        </StyledMobileNavContainer>
      )}
    </StyledComponentContainer>
  )
}

// Prop Types
Nav.propTypes = {
  bankerData: PropTypes.object,
  navIsOpen: PropTypes.bool.isRequired,
  setNavOpen: PropTypes.func.isRequired,
  setIsApplyModalOpen: PropTypes.func.isRequired,
  useHamburgerMenu: PropTypes.bool,
}

Nav.defaultProps = {
  useHamburgerMenu: true,
}

export default Nav
