// Packages
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import * as emailjs from "emailjs-com"
import { useForm, Controller } from "react-hook-form"
import { GatsbyImage } from "gatsby-plugin-image"

// Components
import Button from "@gray-fox-components/components/buttons/button"
import Message from "@gray-fox-components/components/forms/message"
import OutlinedField from "@gray-fox-components/components/forms/outlined-field"

// Utils
import { getMediaQueries } from "@gray-fox-components/utils/utils"
// Styles
const { above } = getMediaQueries()
const StyledComponentContainer = styled.div`
  background-color: var(--primary-lights-2-hex);
  padding: 50px 24px 75px;
  position: relative;
  .background-image {
    bottom: 0;
    left: 0;
    max-height: 315px;
    position: absolute;
    width: 100%;
  }
  ${above.mobile`
    padding: 50px 48px 104px;
  `}
  ${above.tablet`
    padding: 60px 144px;
  `}
`
const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  max-width: 1152px;
  row-gap: 40px;
  ${above.desktop`
    column-gap: 165px;
    flex-direction: row;
  `}
`
const StyledHeader = styled.div`
  .headline {
    font-size: 34px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 30px;
  }
  .subtitle {
    color: var(--primary-darks-6-hex);
    margin-bottom: 10px;
  }
  ${above.desktop`
    padding-top: 107px;
  `}
`
const StyledFormContainer = styled.div`
  background: var(--white-and-black-white-hex);
  border-radius: 6px;
  box-shadow: 0px 3px 32px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 806px;
  margin-inline: auto;
  padding: 60px 24px;
  position: relative;
  row-gap: 40px;
  z-index: 1;
  ${above.mobile`
    padding-inline: 48px;
    width: 438px;
  `}
`
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  .headline {
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    max-width: 327px;
    strong {
      color: var(--primary-darks-5-hex);
      font-weight: 700;
    }
  }
`
const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

// Main Component
function CTAForm(props) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      phone: "",
    },
    mode: "onTouched",
  })

  const goBack = () => {
    if (submissionError) setSubmissionError(false)
    setIsSubmitted(false)
  }

  const onSubmit = data => {
    // SUBMIT FORM
    if (!data.pass) {
      const recipient = () => {
        if (data.name === "test" || data.email === "test@test.com") {
          return "webteam@atlanticbay.com"
        } else if (props.bankerData?.email) {
          return props.bankerData.email
        }
        return "betsyfalls@grayfoxmortgage.com"
      }

      // CREATE FORM DATA
      const templateParams = {
        ...data,
        recipient: recipient(),
        page: `www.grayfoxmortgage.com${props.page}`,
      }
      // Make it clear in the email that empty fields were left empty by adding "Information not provided" to the field values
      Object.keys(data).forEach(key => {
        if (key === "pass") delete templateParams[key]
        else if (!data[key]) {
          templateParams[key] = "Information not provided"
        }
      })

      emailjs
        .send(
          "default_service",
          "gray-fox-default",
          templateParams,
          "user_rZnp1QjHFiOah6cAnsph7"
        )
        .then(function () {
          setIsSubmitted(true)
        })
        .catch(() => {
          setSubmissionError(true)
          setIsSubmitted(true)
        })
    }
  }

  useEffect(() => {
    if (isSubmitted) reset()
  }, [isSubmitted, reset])

  return (
    <StyledComponentContainer>
      <StyledMainContent>
        <StyledHeader>
          <p className="subtitle layout__overline">{props.subtitle}</p>
          <h4 className="headline">{props.headline}</h4>
          <div
            className="description layout__description layout__body--regular"
            dangerouslySetInnerHTML={{
              __html: props.copy,
            }}
          />
        </StyledHeader>
        <StyledFormContainer>
          {!isSubmitted ? (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <p className="headline">{props.form.headline}</p>
              <StyledFields>
                <OutlinedField type="pass" watch={watch} register={register} />
                {props.form.fields.map((field, i) => {
                  return (
                    <OutlinedField
                      key={`cta-field-${i}`}
                      {...field}
                      register={register}
                      watch={watch}
                      control={control}
                      Controller={Controller}
                      errors={errors}
                    />
                  )
                })}
              </StyledFields>
              <Button
                text="Let's get started"
                type="primary"
                disabled={isValid ? false : true}
              />
            </StyledForm>
          ) : submissionError ? (
            <Message type="error" goBack={goBack} />
          ) : (
            <Message type="success" goBack={goBack} />
          )}
        </StyledFormContainer>
      </StyledMainContent>
      <GatsbyImage
        className="background-image"
        image={props.backgroundImage}
        alt=""
      />
    </StyledComponentContainer>
  )
}

// Prop Types
CTAForm.propTypes = {
  /** Contains gatsbyImageData */
  backgroundImage: PropTypes.object,
  /** HTML string */
  copy: PropTypes.string,
  form: PropTypes.object,
  headline: PropTypes.string,
  /** Page form is on (e.g. /about/) */
  page: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

export default CTAForm
