// Packages
import { getPhoneNumbers } from "@gray-fox-components"

// Data
const bankersDataJSON = require("@data/banker-data.json")

// Functions
export const sortAlphabetically = (data, property, order) => {
  return data.sort((a, b) => {
    if (order.match(/asc/i)) {
      if (a[property] > b[property]) return 1
      if (a[property] === b[property]) return 0
      return -1
    } else if (order.match(/desc/i)) {
      if (b[property] > a[property]) return 1
      if (b[property] === a[property]) return 0
      return -1
    }
  })
}

export const sortByDate = (data, property, order) => {
  return data.sort((a, b) => {
    if (order.match(/asc/i)) {
      return a[property] - b[property]
    } else if (order.match(/desc/i)) {
      return b[property] - a[property]
    }
  })
}

export const getAllBankerData = bankerData => {
  let allBankerData
  if (bankerData) {
    allBankerData = {
      ...bankerData,
      mobile: bankerData.mobilePhone,
      office: bankerData.officePhone,
      extension: bankerData.phoneExtension,
      favoredPhone: bankerData.favoredPhone,
      name: bankerData.fullName,
      headshot: bankerData.headshot?.gatsbyImageData,
      socials:
        bankerData.facebook || bankerData.instagram || bankerData.linkedin
          ? {
              facebook: bankerData.facebook,
              instagram: bankerData.instagram,
              linkedin: bankerData.linkedin,
            }
          : null,
    }
    allBankerData.phone = getPhoneNumbers(allBankerData)?.primaryPhone
    allBankerData.phoneText = getPhoneNumbers(allBankerData)?.primaryPhoneText
  }
  return allBankerData
}

// Variables
export const allBankersData = bankersDataJSON.map(bankerData => {
  return getAllBankerData(bankerData)
})

export const allNonTestBankersData = allBankersData.filter(
  mb => mb.slug !== "test"
)

export const originators = allNonTestBankersData.filter(mb => mb.originator)
