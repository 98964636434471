// Components
export { default as Apply } from "./components/apply"
export { default as Carousel } from "./components/carousel"
export { default as Calculator } from "./components/calculator/calculator"
export { default as CalculatorPanel } from "./components/calculator/calculator-panel"
export { default as Footer } from "./components/footer"
export { default as Modal } from "./components/modal"
export { default as Nav } from "./components/nav"
export { default as SEO } from "./components/seo"

// -Buttons
export { default as Button } from "./components/buttons/button"
export { default as ButtonSocialIcon } from "./components/buttons/social"

// -Forms
export { default as OutlinedField } from "./components/forms/outlined-field"
export { default as Message } from "./components/forms/message"
export { default as CTAForm } from "./components/forms/cta-form"

// -Icons
export { default as Icon } from "./components/icons/icon"
export { default as FormIcon } from "./components/icons/form"

// -Images
export { default as BackgroundImage } from "./components/images/background"

// -Links
export { default as Link } from "./components/links/link"

// -MB
export { default as Card } from "./components/mb/card"
export { default as Licensing } from "./components/mb/licensing"

/********************************************************************/

// Hooks

export { default as useWindowSize } from "./hooks/use-window-size"

/********************************************************************/

// Images
export const EqualOpportunityLenderLogoImage =
  require("./images/equal-opportunity-lender-logo.svg").default
export const GrayFoxLogoImage = require("./images/gray-fox-logo.svg").default
export const GrayFoxLogoIconWhiteImage =
  require("./images/gray-fox-logo-icon-white.svg").default

/********************************************************************/

// Utils
export {
  colors,
  getMediaQueries,
  getPhoneNumbers,
  mediaBreakpoints,
} from "./utils/utils"

// -CSS
export const CSSVariables = require("./utils/_variables.css")
