// Packages
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import loanCalc from "loan-calc"
import { useForm, Controller } from "react-hook-form"

// Components
import OutlinedField from "@gray-fox-components/components/forms/outlined-field"
import Icon from "@gray-fox-components/components/icons/icon"

// Utils
import { getMediaQueries } from "@gray-fox-components/utils/utils"

// Styles
const { above } = getMediaQueries()
const StyledComponentContainer = styled.div`
  background: var(--primary-lights-1-hex);
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  padding: 50px 24px;
  row-gap: 40px;
  ${above.mobile`
    border-radius: 6px;
    box-shadow: ${props =>
      props.isModalOpen && "0px 3px 32px 0px rgba(0, 0, 0, 0.1)"};
    padding-inline: 48px;
    width: 582px;
  `}
  ${above.desktop`
    padding: 80px 100px;
    row-gap: 80px;
    width: 878px;
  `}
`
const StyledHeader = styled.div`
  .headline {
    font-size: 34px;
    line-height: 50px;
    margin-bottom: 24px;
    ${above.desktop`
      margin-bottom: 35px;
    `}
  }
`
const StyledSubtitleContainer = styled.div`
  align-items: flex-start;
  column-gap: 9px;
  display: flex;
  justify-content: space-between;
  .close {
    background: transparent;
    border: 0;
    display: flex;
  }
  .subtitle {
    color: var(--primary-darks-6-hex);
    margin-bottom: 12px;
  }
`
const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${above.mobile`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`
const StyledPriceAndPayment = styled.div`
  --gap: 24px;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  ${above.mobile`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / 3;
  `}
  ${above.desktop`
    margin-bottom: calc(60px - var(--gap));
  `}
`
const StyledAmountContainer = styled.div`
  display: grid;
  grid-template-rows: min-content auto;
  .headline {
    font-weight: 500;
    margin-bottom: 12px;
    ${above.desktop`
      font-weight: 400;
    `}
  }
  .monthly-payment {
    align-self: center;
    font-weight: 500;
    ${above.desktop`
      font-weight: 400;
    `}
  }
`

// Main Component
function Calculator(props) {
  const { register, control, watch } = useForm({
    mode: "onTouched",
  })

  const watchAllFields = watch()

  const [monthlyPayment, setMonthlyPayment] = useState("$ 0/month")

  const calculateMonthlyPayment = values => {
    const monthlyPayment = loanCalc.paymentCalc({
      amount: values.amount - values.downpayment,
      rate: values.interest_rate,
      termMonths: values.loan_term * 12,
    })
    setMonthlyPayment(`$ ${monthlyPayment}/month`)
  }
  const cleaveOptions = {
    numeral: true,
    prefix: "$ ",
    noImmediatePrefix: true,
  }
  const fields = [
    {
      placeholder: "Downpayment",
      name: "downpayment",
      inputFormat: "numeral",
      cleaveOptions,
    },
    {
      placeholder: "Loan Term (Years)",
      name: "loan_term",
      inputFormat: "numeral",
      rules: {
        pattern: /\d{2}/,
      },
    },
    {
      placeholder: "Interest Rate",
      name: "interest_rate",
      inputFormat: "numeral",
      cleaveOptions: {
        ...cleaveOptions,
        prefix: "%",
        tailPrefix: true,
      },
    },
    {
      placeholder: "Zip Code",
      name: "zip_code",
      rules: {
        pattern: /\d{5}/,
      },
    },
  ]
  useEffect(() => {
    if (
      watchAllFields.amount &&
      watchAllFields.downpayment &&
      watchAllFields.interest_rate &&
      watchAllFields.loan_term
    ) {
      const fieldValues = { ...watchAllFields }
      Object.keys(fieldValues).forEach(key => {
        if (key === "interest_rate")
          fieldValues[key] = parseFloat(fieldValues[key])
        else fieldValues[key] = fieldValues[key].replace(/\D/g, "")
      })
      calculateMonthlyPayment(fieldValues)
    }
  }, [watchAllFields])

  return (
    <StyledComponentContainer>
      <StyledHeader>
        <StyledSubtitleContainer>
          <p className="subtitle layout__overline">
            How Much Home Can You Truly Afford?
          </p>
          {props.closeModal && (
            <button
              className="close"
              onClick={e => {
                props.closeModal()
              }}
            >
              <Icon icon="x" width={46} height={46} />
            </button>
          )}
        </StyledSubtitleContainer>
        <h4 className="headline layout__heading--bold">Mortgage Calculator</h4>
        <p className="layout__body--regular">
          It&apos;s more than just numbers. It&apos;s about finding a home that
          feels right. Start your journey with confidence using our tailored
          mortgage calculator.
        </p>
      </StyledHeader>
      <StyledFields>
        <StyledPriceAndPayment>
          <StyledAmountContainer>
            <p className="headline layout__h5">Home Price</p>
            <OutlinedField
              placeholder="Amount"
              name="amount"
              inputFormat="numeral"
              cleaveOptions={cleaveOptions}
              register={register}
              watch={watch}
              control={control}
              Controller={Controller}
            />
          </StyledAmountContainer>
          <StyledAmountContainer>
            <p className="headline layout__h5">Monthly Payment</p>
            <p className="monthly-payment layout__h5">{monthlyPayment}</p>
          </StyledAmountContainer>
        </StyledPriceAndPayment>
        {fields.map(field => {
          return (
            <OutlinedField
              key={`cta-field-${field.name}`}
              {...field}
              register={register}
              watch={watch}
              control={control}
              Controller={Controller}
            />
          )
        })}
      </StyledFields>
      <p className="layout__disclaimer">
        *This calculator is made available to you as an informational tool. All
        examples are hypothetical and are for illustrative purposes only. Any
        amount calculated is an estimate only. The actual amount may be higher
        or lower depending on individual scenarios, credit scores and market
        conditions. Gray Fox Mortgage does not guarantee the applicability of
        the above terms in regards to your individual circumstances. This
        information is not intended to replace the advice of a tax or financial
        professional.
      </p>
    </StyledComponentContainer>
  )
}

// Prop Types
Calculator.propTypes = {
  closeModal: PropTypes.func,
}

export default Calculator
