// Packages
import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

// Components
import MBCard from "@gray-fox-components/components/mb/card"
import Icon from "@gray-fox-components/components/icons/icon"
import Button from "@gray-fox-components/components/buttons/button"

// Utils
import { getMediaQueries } from "@gray-fox-components/utils/utils"

// Styles
const { above } = getMediaQueries()
const StyledComponentContainer = styled.div`
  background: var(--primary-lights-1-hex);
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding: 50px 24px;
  .continue {
    margin-inline: auto;
    width: 327px;
  }
  ${above.mobile`
    border-radius: 6px;
    box-shadow: ${props =>
      props.isModalOpen && "0px 3px 32px 0px rgba(0, 0, 0, 0.1)"};
    margin-inline: auto;
    padding-inline: 48px;
    width: 582px;
  `}
  ${above.desktop`
    padding: 80px 100px;
    width: 878px;
  `}
`
const StyledHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto 46px;
  grid-template-rows: auto auto;
  .close {
    background: transparent;
    border: 0;
    display: flex;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
`
const StyledHeader = styled.div`
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  .subtitle {
    color: var(--white-and-black-6-hex);
  }
  .headline {
    font-size: 34px;
    font-weight: 700;
    line-height: 50px;
  }
`
const StyledBankers = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  gap: 24px;
  ${above.desktop`
    display: grid;
    grid-template-columns: 327px 327px;
    margin-inline: 0;
  `}
`

// Main Component
function Apply(props) {
  const [selectedBanker, setSelectedBanker] = useState(null)
  return (
    <StyledComponentContainer isModalOpen={props.closeModal}>
      <StyledHeaderContainer>
        <StyledHeader>
          <h4 className="headline">Get Started</h4>
          <p className="subtitle layout__body--smaller">
            Choose the Mortgage Banker you would like to proceed with:
          </p>
        </StyledHeader>
        {props.closeModal && (
          <button
            className="close"
            onClick={e => {
              props.closeModal()
            }}
          >
            <Icon icon="x" width={46} height={46} />
          </button>
        )}
      </StyledHeaderContainer>
      <StyledBankers>
        {props.bankers.map(banker => {
          return (
            <MBCard
              key={banker.slug}
              {...banker}
              selectedBanker={selectedBanker}
              selectBanker={() => setSelectedBanker(banker)}
            />
          )
        })}
      </StyledBankers>
      <Button
        text="Continue"
        type={selectedBanker ? "primary" : "disabled"}
        className={"continue"}
        linkAttributes={{
          href: selectedBanker?.apply || "#",
        }}
      />
    </StyledComponentContainer>
  )
}

// Prop Types
Apply.propTypes = {
  closeModal: PropTypes.func,
  bankers: PropTypes.array.isRequired,
}

export default Apply
