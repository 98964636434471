// Packages
import React from "react"
import PropTypes from "prop-types"

// Utils
import { colors } from "@gray-fox-components/utils/utils"

// Main Component
export default function FormIcon({
  borderColor,
  innerColor,
  icon,
  width,
  height,
}) {
  switch (icon) {
    default:
    case "disabled-checkbox":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="1.5"
            fill="white"
            stroke={borderColor || colors["grey-3"].hex}
          />
        </svg>
      )
    case "disabled-radio":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99999 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 9.99999C18.3333 5.39762 14.6024 1.66666 9.99999 1.66666C5.39762 1.66666 1.66666 5.39762 1.66666 9.99999C1.66666 14.6024 5.39762 18.3333 9.99999 18.3333Z"
            stroke={borderColor || colors["grey-3"].hex}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.10834 4.10834L15.8917 15.8917"
            stroke={borderColor || colors["grey-3"].hex}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    case "selected-checkbox":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="1.5"
            fill="white"
            stroke={borderColor}
          />
          <rect
            x="3"
            y="3"
            width="10"
            height="10"
            rx="2"
            fill={innerColor || colors["pacific-blue"].hex}
          />
        </svg>
      )
    case "selected-radio":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8"
            cy="8"
            r="7.5"
            stroke={borderColor || colors["pacific-blue"].hex}
          />
          <circle
            cx="8"
            cy="8"
            r="5"
            fill={innerColor || colors["pacific-blue"].hex}
          />
        </svg>
      )
    case "unselected-checkbox":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="1.5"
            fill="white"
            stroke={borderColor}
          />
        </svg>
      )
    case "unselected-radio":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7.5" stroke={borderColor} />
        </svg>
      )
  }
}

FormIcon.propTypes = {
  /**
   * Sets color of outer border
   */
  borderColor: PropTypes.string,
  /**
   * Sets color of inner circle
   */
  innerColor: PropTypes.string,
  /**
   * Name of icon
   */
  icon: PropTypes.string.isRequired,
  /**
   * Sets width of icon
   */
  width: PropTypes.number,
  /**
   * Sets height of icon
   */
  height: PropTypes.number,
  /**
   * Sets stroke width of icon
   */
}

FormIcon.defaultProps = {
  color: "#1a202c", // White And Black Black
  height: 16,
  width: 16,
}
