// Packages
import { createGlobalStyle } from "styled-components"
import { getMediaQueries } from "@gray-fox-components"

// Styles
const { above } = getMediaQueries()
export const LayoutStyles = createGlobalStyle`
a {
  text-decoration: none;
}
body {
  margin: 0;
}
button {
  cursor: pointer;
}
h1, .layout__h1 {
  font-size: 56px;
  line-height: 70px;
  ${above.tablet`
    font-size: 64px;
    line-height: 80px;
  `}
}
h2, .layout__h2 {
  font-size: 48px;
  line-height: 60px;
  ${above.tablet`
    font-size: 56px;
    line-height: 70px;
  `}
}
h3, .layout__h3 {
  font-size: 34px;
  line-height: 50px;
  ${above.tablet`
    font-size: 48px;
    line-height: 60px;
  `}
}
h4, .layout__h4 {
  font-size: 24px;
  line-height: 35px;
  ${above.tablet`
    font-size: 34px;
    line-height: 50px;
  `}
}
h5, .layout__h5 {
  font-size: 16px;
  line-height: 25px;
  ${above.tablet`
    font-size: 24px;
    line-height: 35px;
  `}
}
* {
  color: var(--primary-colors-dark-navy-blue-hex);
  margin: 0;
  padding: 0;
}
.layout__a {
  color: var(--primary-darks-5-hex);
  text-decoration: underline;
}
.layout__ul {
  li {
    --line-height: 32px;
    line-height: 32px;
    list-style-type: none;
    padding-left: 26px;
    position: relative;
    &:before {
      background-color: var(--primary-colors-dark-navy-blue-hex);
      border-radius: 50%;
      content: "";
      height: 6px;
      left: 0;
      position: absolute;
      top: calc(var(--line-height) / 2);
      transform: translateY(-50%);
      width: 6px;
    }
  }
}
.layout__hr {
  background: var(--white-and-black-5-hex);
  height: 2px;
}

.layout__body--regular {
  &, * {
    font-size: 20px;
    line-height: 36px;
  }
  .layout__ul {
    li {
      --line-height: 36px;
      line-height: 36px;
    }
  }
}
.layout__body--smaller {
  &, & * {
    font-size: 18px;
    line-height: 32px;
  }
}
.layout__caption {
  &, & * {
    font-size: 16px;
    line-height: 25px;
  }
  &--medium, &--medium * {
    font-weight: 500;
  }
}
.layout__description {
  max-width: 956px;
  &, & * {
    &p {
      margin-bottom: 32px;
    }
    &p:last-of-type {
      margin-bottom: 0;
    }
  }
  &--centered {
    margin-inline: auto;
  }
}
.layout__disclaimer {
  &, & * {
    font-size: 14px;
    line-height: 20px;
  }

}
.layout__heading--bold {
  font-weight: 700;
}
.layout__helper, .layout__helper * {
  color: var(--white-and-black-6-hex);
  font-size: 12px;
  line-height: 20px;
}
.layout__img--rounded, .layout__img--rounded img {
  border-radius: 4px;
}
.layout__main-content {
  margin-inline: auto;
  max-width: 1152px;
}
.layout__overline {
  font-size: 20px;
  font-weight: 500;
  line-height: 29px; 
}
.layout__padding-container {
  padding-inline: 24px;
  ${above.mobile`
    padding-inline: 48px;
  `}
  ${above.tablet`
    padding-inline: 144px;
  `}  
}
`
