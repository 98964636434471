// Packages
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

// Styles
const StyledComponentContainer = styled.div`
  &.default-headshot-component {
    --headshot-size: 100px;
    align-items: center;
    background-color: var(--primary-colors-dark-navy-blue-hex);
    color: var(--white-and-black-white-hex);
    display: flex;
    font-size: 24px;
    font-weight: 700;
    justify-content: center;
    height: var(--headshot-size);
    max-height: 100%;
    max-width: 100%;
    width: var(--headshot-size);
    overflow: hidden;
    &--large {
      --headshot-size: 222px;
      font-size: 50px;
    }
  }
`

// Main Component
function DefaultHeadshot(props) {
  return (
    <StyledComponentContainer
      className={`default-headshot-component layout__img--rounded${
        props.className ? ` ${props.className}` : ""
      }`}
    >
      {`${props?.firstName && props?.firstName.charAt()} ${
        props?.lastName && props?.lastName.charAt()
      }`}
    </StyledComponentContainer>
  )
}

// Prop Types
DefaultHeadshot.propTypes = {
  className: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
}

export default DefaultHeadshot
