// Packages
import React from "react"

// Styles
import Layout from "@components/layout"
import { LayoutStyles } from "@styles/layout"

// APIs
export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <LayoutStyles />
      <Layout {...props}>{element}</Layout>
    </>
  )
}
