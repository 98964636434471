// Packages
import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"

// Utils
import { getMediaQueries } from "@gray-fox-components/utils/utils"

// Styles
const { above } = getMediaQueries()
const PageStyles = createGlobalStyle`
  body {
    overflow: ${props => props.isModalOpen && "hidden"};
    position: ${props => props.isModalOpen && "fixed"};
  }
`
const Overlay = styled(motion.div)`
  background: var(--primary-darks-dark-navy-blue-hex);
  min-height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
`

const ModalContainer = styled(motion.div)`
  background: var(--abmg-grey-ice);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: absolute;
  width: 100%;
  ${above.mobile`
    padding-block: 100px;
  `}
  ${above.tablet`
  `}
`

// Framer Animation
const animationDuration = 0.2
// const ease = 'easeInOut';

const overlayVariants = {
  hidden: {
    opacity: 0,
    transition: {
      // ease,
      // duration: animationDuration,
    },
  },
  fadeIn: {
    opacity: 1,
    transition: {
      // ease,
      // duration: animationDuration,
    },
  },
  fadeOut: {
    opacity: 0,
    transition: {
      // ease,
      // duration: animationDuration,
      delay: animationDuration,
    },
  },
}

const modalVariants = {}

// Main Component
function Modal({ isModalOpen, modalWidth, children }) {
  return (
    <>
      <PageStyles isModalOpen={isModalOpen} />
      <AnimatePresence>
        {isModalOpen && (
          <Overlay
            className="overlay"
            key="overlay"
            variants={overlayVariants}
            initial="hidden"
            animate="fadeIn"
            exit="fadeOut"
          >
            <ModalContainer
              key="modal"
              className="modal"
              initial={{ right: `-${modalWidth}px` }}
              animate={{
                right: 0,
                transition: {
                  // ease,
                  duration: animationDuration,
                },
              }}
              exit={{
                right: `-${modalWidth}px`,
                transition: {
                  // ease,
                  duration: animationDuration,
                },
              }}
              variants={modalVariants}
            >
              {children}
            </ModalContainer>
          </Overlay>
        )}
      </AnimatePresence>
    </>
  )
}

export default Modal

Modal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  modalWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
}
