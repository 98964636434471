// Packages
import { Link } from "gatsby-link"
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// Components
import ButtonSocialIcon from "@gray-fox-components/components/buttons/social"

// Images
import GrayFoxLogoIconWhiteImage from "@gray-fox-components/images/gray-fox-logo-icon-white.svg"
import EqualOpportunityLenderLogoImage from "@gray-fox-components/images/equal-opportunity-lender-logo.svg"

// Utils
import { getMediaQueries } from "@gray-fox-components/utils/utils"

// Data
const SiteDataJSON = require("@data/site.json")

// Styles
const { above } = getMediaQueries()
const StyledComponentContainer = styled.div`
  background-color: var(--primary-colors-dark-navy-blue-hex);
  padding: 70px 24px 120px;
  * {
    color: var(--white-and-black-white-hex);
  }
  ${above.mobile`
    padding-inline: 48px;
  `}
  ${above.tablet`
    padding-inline: 144px;
  `}
`
const StyledMainContainer = styled.div`
  a {
    :visited,
    :hover {
      color: inherit;
    }
  }
`
const StyledFooterTop = styled.div`
  display: grid;
  margin-bottom: 90px;
  row-gap: 60px;
  ${above.desktop`
    column-gap: 153px;
    grid-template-columns: auto auto;
  `}
`
const StyledFooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  row-gap: 10px;
  * {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    letter-spacing: 0.28px;
    line-height: 24px;
  }
  ${above.mobile`
    flex-direction: row;
    justify-content: space-between;
  `}
`
const StyledLogoAndLinks = styled.div`
  display: grid;
  row-gap: 60px;
  .logo {
    width: 65px;
  }
  ${above.desktop`
    column-gap: 64px;
    grid-template-columns: auto auto;
  `}
`
const StyledLinksContainer = styled.div`
  .headline {
    color: var(--white-and-black-white-hex);
    margin-bottom: 16px;
  }
`
const StyledLinks = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  row-gap: 16px;
`
const StyledSocialsAndDisclosureContainer = styled.div`
  display: grid;
  row-gap: 30px;
  .disclosure {
    ${above.desktop`
      text-align: right;
    `}
  }
  .equal-opportunity-lender-logo {
    justify-self: start;
    ${above.desktop`
      justify-self: end;
    `}
  }
`
const StyledSocials = styled.div`
  display: flex;
  column-gap: 24px;
  ${above.desktop`
    justify-self: flex-end;
  `}
`

const Footer = props => {
  const socials = props.bankerData?.socials
    ? props.bankerData?.socials
    : SiteDataJSON.socials
  return (
    <StyledComponentContainer>
      <StyledMainContainer className="layout__main-content">
        <StyledFooterTop>
          <StyledLogoAndLinks>
            <img
              className="logo"
              src={GrayFoxLogoIconWhiteImage}
              alt="Gray Fox Mortgage"
            />
            <StyledLinksContainer>
              <p className="headline layout__overline">Additional Links</p>
              <StyledLinks className="layout__body--regular">
                <li>
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/legal/">Legal</Link>
                </li>
                <li>
                  <a
                    href="https://www.nmlsconsumeraccess.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NMLS Consumer Access
                  </a>
                </li>
              </StyledLinks>
            </StyledLinksContainer>
          </StyledLogoAndLinks>
          <StyledSocialsAndDisclosureContainer>
            <StyledSocials>
              {Object.keys(socials).map(key => {
                return (
                  <ButtonSocialIcon
                    key={key}
                    icon={key}
                    type="secondary"
                    linkAttributes={{
                      href: socials[key],
                      target: "_blank",
                      rel: "noreferrer",
                    }}
                  />
                )
              })}
            </StyledSocials>
            <p className="disclosure layout__caption">
              Loan programs may change at any time with or without notice.
              Information deemed reliable but not guaranteed. All loans subject
              to income verification, credit approval and property appraisal.
              Not a commitment to lend. Gray Fox Mortgage, LLC NMLS #2511214 (
              <a
                href="https://www.nmlsconsumeraccess.org/"
                target="_blank"
                rel="noreferrer"
              >
                nmlsconsumeraccess.org
              </a>
              ) is an Equal Housing Lender.
            </p>
            <img
              className="equal-opportunity-lender-logo"
              src={EqualOpportunityLenderLogoImage}
              alt="Equal Opportunity Lender"
            />
          </StyledSocialsAndDisclosureContainer>
        </StyledFooterTop>
        <hr />
        <StyledFooterBottom>
          <p className="copyright">
            © {new Date().getFullYear()} Gray Fox Mortgage. All rights reserved.
          </p>
          <p className="address">{SiteDataJSON.address}</p>
        </StyledFooterBottom>
      </StyledMainContainer>
    </StyledComponentContainer>
  )
}

Footer.propTypes = {
  bankerData: PropTypes.object,
}

export default Footer
