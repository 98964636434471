// Packages
import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "@gray-fox-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// Components
import Calculator from "@gray-fox-components/components/calculator/calculator"
import Modal from "@gray-fox-components/components/modal"

// Utils
import { getMediaQueries } from "@gray-fox-components/utils/utils"

// Images
import BackgroundPattern from "@gray-fox-components/images/calculator/background-pattern.svg"

// Styles
const { above } = getMediaQueries()
const StyledComponentContainer = styled.div`
  ${above.desktop`
    padding-block: 60px;
  `}
`
const StyledMainContent = styled.div`
  background-image: url(${BackgroundPattern});
  background-color: var(--primary-lights-2-hex);
  background-position: right top;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: column;
  padding-block: 50px;
  row-gap: 36px;
  .gatsby-image-wrapper {
    margin-inline: auto;
    width: 292px;
    &,
    img {
      border-radius: 2px;
      flex-shrink: 0;
    }
  }
  ${above.mobile`
    column-gap: 52px;
    flex-direction: row;
    padding-inline: 48px;
  `}
  ${above.tablet`
    padding: 60px 144px;
  `}
  ${above.desktop`
    column-gap: 36px;
    padding: 100px 118px;
  `}
`
const StyledHeader = styled.div`
  button {
    width: 100%;
    ${above.mobile`
      width: auto;
    `}
  }
  .description {
    margin-bottom: 40px;
  }

  .headline {
    font-size: 34px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 10px;
  }
`

// Main Component
function CalculatorPanel() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      CalculatorImage: file(
        relativePath: { regex: "/calculator/calculator/" }
        sourceInstanceName: { eq: "gray-fox-images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  return (
    <>
      <StyledComponentContainer>
        <StyledMainContent className="layout__main-content layout__padding-container">
          <StyledHeader>
            <h4 className="headline">How Much Can I Afford?</h4>
            <p className="description layout__body--regular">
              Navigating the housing market starts with understanding your
              budget. Start your journey with confidence using our tailored
              mortgage calculator.
            </p>
            <Button
              text="Use our calculator"
              type="primary"
              functions={{
                onClick: () => setIsModalOpen(true),
              }}
            />
          </StyledHeader>
          <GatsbyImage
            image={data.CalculatorImage.childImageSharp.gatsbyImageData}
            alt="mortgage calculator"
          />
        </StyledMainContent>
      </StyledComponentContainer>
      <Modal isModalOpen={isModalOpen}>
        <Calculator closeModal={() => setIsModalOpen(false)} />
      </Modal>
    </>
  )
}

export default CalculatorPanel
