// Packages
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import { getMediaQueries } from "@gray-fox-components/utils/utils"

// Styles
const { canHover } = getMediaQueries()
const ButtonIcon = styled.button`
  background: transparent;
  border: none;
  display: inline-flex;
  &.secondary {
    --default-background: transparent;
    --default-color: var(--white-and-black-white-hex);
    --hover-color: var(--accent-sunset-5-hex);
    --active-color: var(--accent-sunset-8-hex);
    --disabled-color: var(--white-and-black-7-hex);
    svg {
      circle {
        fill: ${props =>
          props.$circleDefaultColor || "var(--default-background)"};
        stroke: ${props =>
          props.$circleBorderDefaultColor || "var(--default-color)"};
      }
      path[fill] {
        fill: ${props => props.$iconDefaultColor || "var(--default-color)"};
      }
      path[stroke],
      g[stroke] {
        stroke: ${props =>
          props.$iconBorderDefaultColor || "var(--default-color)"};
      }
    }

    ${canHover`
      &:hover {
        svg {
          circle {
            fill: ${props =>
              props.$circleHoverColor || "var(--default-background)"};
            stroke: ${props =>
              props.$circleBorderHoverColor || "var(--hover-color)"};
          }
          path[fill], g[fill] {
            fill: ${props => props.$iconHoverColor || "var(--hover-color)"};
          } 
          path[stroke], g[stroke] {
            stroke: ${props =>
              props.$iconBorderHoverColor || "var(--hover-color)"};
          }
        }
      }
    `}
    &:active {
      svg {
        circle {
          fill: ${props =>
            props.$circleActiveColor || "var(--default-background)"};
          stroke: ${props =>
            props.$circleBorderActiveColor || "var(--active-color)"};
        }
        path[fill],
        g[fill] {
          fill: ${props => props.$iconActiveColor || "var(--active-color)"};
        }
        path[stroke],
        g[stroke] {
          stroke: ${props =>
            props.$iconBorderActiveColor || "var(--active-color)"};
        }
      }
    }
    &:focus {
      svg {
        circle {
          fill: ${props =>
            props.$circleFocusColor || "var(--default-background)"};
          stroke: ${props =>
            props.$circleBorderFocusColor || "var(--default-color)"};
          stroke-width: 2;
        }
        path[fill],
        g[fill] {
          fill: ${props => props.$iconFocusColor || "var(--default-color)"};
        }
        path[stroke],
        g[stroke] {
          stroke: ${props =>
            props.$iconBorderFocusColor || "var(--default-color)"};
        }
      }
    }
    &.disabled,
    &[disabled] {
      svg {
        circle {
          fill: ${props =>
            props.$circleDisabledColor || "var(--default-background)"};
          stroke: ${props =>
            props.$circleBorderDisabledColor || "var(--disabled-color)"};
          stroke-width: 2px;
        }
        path[fill],
        g[fill] {
          fill: ${props => props.$iconDisabledColor || "var(--disabled-color)"};
        }
        path[stroke],
        g[stroke] {
          stroke: ${props =>
            props.$iconBorderDisabledColor || "var(--disabled-color)"};
        }
      }
    }
  }
`

export default function ButtonSocialIcon({
  circleDefaultColor,
  circleActiveColor,
  iconDefaultColor,
  iconActiveColor,
  circleBorderDefaultColor,
  borderActiveColor,
  icon,
  iconHoverColor,
  iconBorderHoverColor,
  iconBorderActiveColor,
  iconBorderDefaultColor,
  circleHeight,
  circleWidth,
  title,
  functions,
  linkAttributes,
  disabled,
  className,
  type,
}) {
  let selectedIcon

  switch (icon) {
    default:
    case "copy":
      selectedIcon = (
        <svg
          width={circleWidth}
          height={circleHeight}
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14" cy="14" r="13.5" stroke="#2D3748" />
          <rect
            x="9"
            y="10.042"
            width="8.11189"
            height="10.1399"
            rx="0.506993"
            stroke="#2D3748"
          />
          <path
            d="M11.042 9.78846V8.50699C11.042 8.22699 11.269 8 11.549 8H18.6469C18.9269 8 19.1539 8.22699 19.1539 8.50699V17.6329C19.1539 17.9129 18.9269 18.1399 18.6469 18.1399H17.1119"
            stroke="#2D3748"
          />
        </svg>
      )
      break
    case "facebook":
      selectedIcon = (
        <svg
          fill="none"
          height={circleHeight}
          viewBox="0 0 32 32"
          width={circleWidth}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="15" stroke="#fff" />
          <path
            d="m17.0912 16.9h1.5584l.6234-2.4h-2.1818v-1.2c0-.618 0-1.2 1.2467-1.2h.9351v-2.016c-.2032-.0258-.9706-.084-1.781-.084-1.6925 0-2.8943.9942-2.8943 2.82v1.68h-1.8702v2.4h1.8702v5.1h2.4935z"
            fill="#fff"
          />
        </svg>
      )
      break
    case "instagram":
      selectedIcon = (
        <svg
          fill="none"
          height={circleHeight}
          viewBox="0 0 32 32"
          width={circleWidth}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <clipPath id="a">
            <path d="m10 10h12v12h-12z" />
          </clipPath>
          <g stroke="#fff">
            <circle cx="16" cy="16" r="15" />
            <g clipPath="url(#a)" strokeLinecap="round" strokeLinejoin="round">
              <path d="m18.5 10.9999h-5c-1.3807 0-2.5 1.1193-2.5 2.5v5c0 1.3807 1.1193 2.5 2.5 2.5h5c1.3807 0 2.5-1.1193 2.5-2.5v-5c0-1.3807-1.1193-2.5-2.5-2.5z" />
              <path d="m17.9999 15.6849c.0617.4161-.0094.8411-.2032 1.2145-.1937.3734-.5003.6762-.876.8653-.3758.1892-.8016.255-1.217.1882-.4153-.0669-.799-.263-1.0964-.5604-.2975-.2975-.4936-.6812-.5604-1.0965s-.001-.8411.1881-1.2169.4919-.6823.8653-.8761c.3734-.1937.7984-.2648 1.2146-.2031.4244.0629.8174.2607 1.1208.5642.3034.3034.5012.6963.5642 1.1208z" />
              <path d="m18.75 13.2499h.0063" />
            </g>
          </g>
        </svg>
      )
      break
    case "link":
      selectedIcon = (
        <svg
          width={circleWidth}
          height={circleHeight}
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14" cy="14" r="13.5" stroke="#2D3748" />
          <g clipPath="url(#clip0_1849_1562)">
            <path
              d="M13.0277 14.4863C13.2365 14.7654 13.5028 14.9963 13.8087 15.1634C14.1145 15.3305 14.4527 15.4299 14.8004 15.4548C15.148 15.4797 15.497 15.4295 15.8235 15.3077C16.1501 15.1859 16.4466 14.9953 16.693 14.7488L18.1513 13.2905C18.5941 12.8321 18.8391 12.2181 18.8335 11.5808C18.828 10.9435 18.5724 10.3339 18.1217 9.88328C17.6711 9.43264 17.0615 9.17702 16.4242 9.17148C15.7869 9.16594 15.1729 9.41093 14.7145 9.85367L13.8784 10.6849"
              stroke="#2D3748"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.9713 13.514C14.7625 13.2349 14.4962 13.004 14.1903 12.8369C13.8845 12.6698 13.5463 12.5704 13.1986 12.5455C12.851 12.5206 12.5021 12.5708 12.1755 12.6926C11.849 12.8144 11.5524 13.005 11.306 13.2515L9.84769 14.7098C9.40495 15.1683 9.15996 15.7822 9.1655 16.4195C9.17103 17.0568 9.42665 17.6664 9.8773 18.117C10.3279 18.5677 10.9376 18.8233 11.5748 18.8288C12.2121 18.8344 12.8261 18.5894 13.2845 18.1467L14.1157 17.3154"
              stroke="#2D3748"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1849_1562">
              <rect
                width="11.6667"
                height="11.6667"
                fill="white"
                transform="translate(8.16675 8.16675)"
              />
            </clipPath>
          </defs>
        </svg>
      )
      break
    case "linkedin":
      selectedIcon = (
        <svg
          width={circleWidth}
          height={circleHeight}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="15" stroke="white" />
          <path
            d="M13.2917 12.5725C13.2916 12.8322 13.1883 13.0813 13.0045 13.2649C12.8208 13.4485 12.5716 13.5515 12.3119 13.5514C12.0521 13.5512 11.8031 13.4479 11.6195 13.2642C11.4359 13.0804 11.3329 12.8313 11.333 12.5715C11.3331 12.3118 11.4364 12.0627 11.6202 11.8792C11.804 11.6956 12.0531 11.5925 12.3129 11.5927C12.5726 11.5928 12.8217 11.6961 13.0052 11.8798C13.1888 12.0636 13.2919 12.3128 13.2917 12.5725ZM13.3211 14.2766H11.3624V20.4074H13.3211V14.2766ZM16.4159 14.2766H14.467V20.4074H16.3963V17.1902C16.3963 15.398 18.7321 15.2315 18.7321 17.1902V20.4074H20.6663V16.5242C20.6663 13.5029 17.2092 13.6155 16.3963 15.0993L16.4159 14.2766Z"
            fill="white"
          />
        </svg>
      )
      break
    case "twitter":
      selectedIcon = (
        <svg
          width={circleWidth}
          height={circleHeight}
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14" cy="14" r="13.5" stroke="#2D3748" />
          <g clipPath="url(#clip0_1850_1659)">
            <path
              d="M20 10.2785C19.5585 10.4745 19.084 10.6065 18.586 10.666C19.0945 10.3615 19.485 9.87902 19.6685 9.30402C19.193 9.58602 18.666 9.79102 18.105 9.90152C17.6565 9.42302 17.016 9.12402 16.308 9.12402C14.7185 9.12402 13.5505 10.607 13.9095 12.1465C11.864 12.044 10.05 11.064 8.8355 9.57452C8.1905 10.681 8.501 12.1285 9.597 12.8615C9.194 12.8485 8.814 12.738 8.4825 12.5535C8.4555 13.694 9.273 14.761 10.457 14.9985C10.1105 15.0925 9.731 15.1145 9.345 15.0405C9.658 16.0185 10.567 16.73 11.645 16.75C10.61 17.5615 9.306 17.924 8 17.77C9.0895 18.4685 10.384 18.876 11.774 18.876C16.345 18.876 18.9275 15.0155 18.7715 11.553C19.2525 11.2055 19.67 10.772 20 10.2785Z"
              fill="#2D3748"
            />
          </g>
          <defs>
            <clipPath id="clip0_1850_1659">
              <rect
                width="12"
                height="12"
                fill="white"
                transform="translate(8 8)"
              />
            </clipPath>
          </defs>
        </svg>
      )
      break
    case "web":
      selectedIcon = (
        <svg
          width={circleWidth}
          height={circleHeight}
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14.5" cy="14" r="13.5" stroke="#5C6677" />
          <path
            d="M14.5 19C17.2614 19 19.5 16.7614 19.5 14C19.5 11.2386 17.2614 9 14.5 9C11.7386 9 9.5 11.2386 9.5 14C9.5 16.7614 11.7386 19 14.5 19Z"
            stroke="#5C6677"
            strokeWidth="0.9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 14H19.5"
            stroke="#5C6677"
            strokeWidth="0.9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5 9C15.7506 10.3692 16.4614 12.146 16.5 14C16.4614 15.854 15.7506 17.6308 14.5 19C13.2494 17.6308 12.5386 15.854 12.5 14C12.5386 12.146 13.2494 10.3692 14.5 9V9Z"
            stroke="#5C6677"
            strokeWidth="0.9"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
      break
    case "zillow":
      selectedIcon = (
        <svg
          width={circleWidth}
          height={circleHeight}
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14.5" cy="14" r="13.5" stroke="#5C6677" />
          <g clipPath="url(#clip0_532_159)">
            <path
              d="M16.2661 10.4663C16.3163 10.4551 16.3387 10.4719 16.3692 10.5054C16.5422 10.6981 17.0954 11.3746 17.2457 11.5591C17.2736 11.5926 17.2543 11.6292 17.2319 11.6486C16.1154 12.5207 14.8729 13.7563 14.1807 14.6397C14.1669 14.6595 14.1781 14.6595 14.1892 14.6565C15.3952 14.1365 18.2283 13.3061 19.5042 13.0742V11.4559L14.5105 7.50006L9.50818 11.4529V13.2222C11.0572 12.3001 14.6415 10.8714 16.2661 10.4663V10.4663Z"
              fill="#5C6677"
            />
            <path
              d="M12.2008 17.2648C12.1592 17.2871 12.1252 17.2816 12.0917 17.248L11.1594 16.1354C11.1345 16.1044 11.1315 16.0877 11.165 16.0404C11.8856 14.9837 13.3565 13.3369 14.2944 12.6411C14.3112 12.6299 14.3056 12.6157 14.2858 12.6243C13.3088 12.943 10.5315 14.1394 9.50427 14.7209V18.5H19.5007V14.8607C18.1385 15.0925 14.0574 16.3087 12.2008 17.2648Z"
              fill="#5C6677"
            />
          </g>
          <defs>
            <clipPath id="clip0_532_159">
              <rect
                width="10"
                height="11"
                fill="white"
                transform="translate(9.50378 7.5)"
              />
            </clipPath>
          </defs>
        </svg>
      )
      break
  }

  return (
    <ButtonIcon
      {...functions}
      {...linkAttributes}
      className={`${type ? `${type}` : ""}${
        disabled ? " disabled" : ""
      } button${className ? ` ${className}` : ""}`}
      $circleDefaultColor={circleDefaultColor}
      $circleActiveColor={circleActiveColor}
      $iconDefaultColor={iconDefaultColor}
      $iconActiveColor={iconActiveColor}
      $circleBorderDefaultColor={circleBorderDefaultColor}
      $borderActiveColor={borderActiveColor}
      $iconHoverColor={iconHoverColor}
      $iconBorderHoverColor={iconBorderHoverColor}
      $iconBorderActiveColor={iconBorderActiveColor}
      $iconBorderDefaultColor={iconBorderDefaultColor}
      as={linkAttributes ? (linkAttributes.href ? "a" : Link) : "button"}
    >
      {selectedIcon}
    </ButtonIcon>
  )
}

ButtonSocialIcon.propTypes = {
  /**
   * Name of icon
   */
  icon: PropTypes.string.isRequired,
  /**
   * Sets height of icon circle
   */
  circleHeight: PropTypes.number,
  /**
   * Sets width of icon circle
   */
  circleWidth: PropTypes.number,
  /**
   * Sets default color of icon circle
   */
  circleDefaultColor: PropTypes.string,
  /**
   * Sets active color of icon circle
   */
  circleActiveColor: PropTypes.string,
  /**
   * Sets default color of icon
   */
  iconDefaultColor: PropTypes.string,
  /**
   * Sets active color of icon
   */
  iconActiveColor: PropTypes.string,
  /** Sets hover color of icon */
  iconHoverColor: PropTypes.string,
  /**
   * Sets active color of icon border
   */
  iconBorderActiveColor: PropTypes.string,
  /**
   * Sets default color of icon border
   */
  iconBorderDefaultColor: PropTypes.string,
  /** Sets hover color of icon border */
  iconBorderHoverColor: PropTypes.string,
  /**
   * Sets default color of border around icon circle
   */
  circleBorderDefaultColor: PropTypes.string,
  /**
   * Sets active color of border around icon circle
   */
  borderActiveColor: PropTypes.string,
  /**
   * Sets title of icon for accessibility
   */
  title: PropTypes.string,
  /**
   * Button Functions, e.g. { onClick: () => {}}
   */
  functions: PropTypes.shape(),
  /**
   * Link Attributes e.g. { to: "/", target: "_blank"}
   */
  linkAttributes: PropTypes.shape(),
  /** Class name of icon */
  className: PropTypes.string,
  /** Is button disabled? */
  disabled: PropTypes.bool,
  /** Button Type */
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "custom",
  ]),
}

ButtonSocialIcon.defaultProps = {
  circleHeight: 32,
  circleWidth: 32,
}
