// Packages
import React from "react"
import PropTypes from "prop-types"

// Main Component
function Licensing(props) {
  if (!props.licenses) return null
  const scenario = () => {
    // If the MB isn't listed in GA use default licensing format
    if (
      !props.licenses?.includes("GA") &&
      !props.licenses?.includes("GA TAO")
    ) {
      return "default"
    }
    // If the MB is only licensed in GA
    else if (props.licenses?.length < 2) {
      if (props.licenses?.includes("GA")) {
        return "ga-standard"
      } else {
        return "ga-tao"
      }
    }
    // If the MB is licensed in GA and other states
    else {
      if (props.licenses?.includes("GA")) {
        return "ga-plus-standard"
      } else {
        return "ga-plus-tao"
      }
    }
  }

  switch (scenario()) {
    default:
      return (
        <span>
          {`Licensed in `}
          {props.licenses?.map(licensedState => (
            <span key={licensedState}>
              {`${licensedState}${
                props.licenses?.length === 2 &&
                props.licenses?.indexOf(licensedState) <
                  props.licenses?.length - 1
                  ? " & "
                  : ""
              }${
                props.licenses?.length > 2 &&
                props.licenses?.indexOf(licensedState) <
                  props.licenses?.length - 1
                  ? ", "
                  : ""
              }`}
            </span>
          ))}
        </span>
      )
    case "ga-standard":
      return (
        <span>{`Georgia Residential Mortgage Licensee # ${props.stateSpecificLicense}`}</span>
      )
    case "ga-tao":
      return <span>{`GA Temporary Authority`}</span>
    case "ga-plus-standard":
      return (
        <span>
          {`Licensed in `}
          {props.licenses?.map(licensedState => (
            <span key={licensedState}>
              {`${
                licensedState === "GA"
                  ? "GA # " + props.stateSpecificLicense
                  : licensedState
              }${
                props.licenses?.length === 2 &&
                props.licenses?.indexOf(licensedState) <
                  props.licenses?.length - 1
                  ? " & "
                  : ""
              }${
                props.licenses?.length > 2 &&
                props.licenses?.indexOf(licensedState) <
                  props.licenses?.length - 1
                  ? ", "
                  : ""
              }`}
            </span>
          ))}
        </span>
      )
    case "ga-plus-tao":
      return (
        <span>
          {`Licensed in `}
          {props.licenses?.map((licensedState, i) => (
            <span key={`${licensedState}-${i}`}>
              {`${licensedState === "GA TAO" ? "" : licensedState}${
                props.licenses?.length > 2 &&
                licensedState !== "GA TAO" &&
                props.licenses?.indexOf(licensedState) <
                  props.licenses?.length - 1
                  ? ", "
                  : ""
              }`}
            </span>
          ))}
          <br />
          GA Temporary Authority
        </span>
      )
  }
}

Licensing.propTypes = {
  licenses: PropTypes.array.isRequired,
  /** Only applies to Georgia currently */
  stateSpecificLicense: PropTypes.string,
}

export default Licensing
