// Styles
import "@styles/reset.css"

// APIs
export { wrapPageElement } from "./gatsby-shared"
export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    // Use timeout so the scroll will fire properly in chromium browsers
    setTimeout(() => {
      const scrollToElement = document.querySelector(hash)
      if (scrollToElement) scrollToElement.scrollIntoView()
    }, 100)
  }
}
