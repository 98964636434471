// Packages
import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Carousel as ReactResponsiveCarousel } from "react-responsive-carousel"
import { isMobile } from "react-device-detect"
import styled from "styled-components"

// Components
import Icon from "@gray-fox-components/components/icons/icon"

// Hooks
import useWindowSize from "@gray-fox-components/hooks/use-window-size"

// Utils
import { colors, getMediaQueries } from "@gray-fox-components/utils/utils"

// Styles
import "react-responsive-carousel/lib/styles/carousel.min.css"
const { above } = getMediaQueries()
const StyledComponentContainer = styled.div`
  .carousel {
    li {
      padding-left: 0;
      &:before {
        all: unset;
      }
    }
    &-slider {
      display: flex;
      flex-direction: column;
    }
    .control {
      background: transparent;
      border: none;
      display: ${props =>
        props.isMobile && props.showArrowsOnMobileDevice === false
          ? `none`
          : `flex`};
      flex-direction: column;
      justify-content: center;
      position: absolute;
      height: 100%;
      top: 0;
      z-index: 10;
      &.control-next {
        right: 0;
      }
      &.control-prev {
        left: 0;
      }
    }
    .control button {
      align-items: center;
      border-radius: 4px;
      background: var(--white-and-black-white-hex);
      box-shadow: 0px 0.75694px 12.11111px 0px rgba(0, 0, 0, 0.1);
      border: none;
      display: flex;
      justify-content: center;
      padding: 12px;
    }
    .control-dots {
      display: flex;
      gap: 8px;
      justify-content: center;
      margin: 20px 0 0;
      order: 4;
      position: static;
      .indicator-button {
        background: transparent;
        border: none;
        .indicator {
          background: var(--primary-lights-light-navy-blue-hex);
          border-radius: 50%;
          height: 8px;
          width: 8px;
        }
        &.active .indicator {
          background: var(--primary-lights-8-hex);
        }
      }
    }
    .slide {
      align-items: center;
      display: flex;
      overflow: hidden;
    }
  }
  .disabled {
    color: var(--primary-colors-light-navy-blue-hex);
    svg path {
      stroke: var(--primary-colors-light-navy-blue-hex);
    }
  }
`
const StyledMobileArrowControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  ${above.mobile`
    display: none;
  `}
`
const StyledMobileArrowControl = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: var(--orca-grey-hex);
  display: flex;
  font-size: 20px;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 20px;
`

// Main Component
function Carousel(props) {
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [isMobileDevice, setIsMobileDevice] = useState(isMobile)
  const windowWidth = useWindowSize().width
  const prevArrowButton = useRef(null)
  const nextArrowButton = useRef(null)

  useEffect(() => {
    if (windowWidth < 951)
      setIsMobileDevice(prev => {
        if (!prev) return true
        else return prev
      })
    if (windowWidth > 950)
      setIsMobileDevice(prev => {
        if (prev) return false
        else return prev
      })
  }, [windowWidth])

  const carousel = useRef(null)

  useEffect(() => {
    if (props.onChange) props.onChange(carouselIndex)
  }, [carouselIndex, props])

  return (
    <StyledComponentContainer
      isMobile={isMobileDevice}
      showArrowsOnMobileDevice={props.showArrowsOnMobileDevice}
      ref={carousel}
    >
      <ReactResponsiveCarousel
        showStatus={props.showStatus}
        autoPlay={props.autoPlay}
        swipeable={props.swipeable}
        emulateTouch={props.emulateTouch}
        infiniteLoop={props.infiniteLoop}
        interval={props.interval}
        showThumbs={props.showThumbs}
        selectedItem={props.selectedItem || 0}
        dynamicHeight={props.dynamicHeight}
        onChange={index => {
          setCarouselIndex(index)
        }}
        renderArrowNext={(clickHandler, hasPrev, label) => (
          <div
            className={`${
              carouselIndex === props.items.length - 1 && !props.infiniteLoop
                ? "disabled"
                : ""
            } control control-next`}
          >
            <button onClick={clickHandler} ref={nextArrowButton}>
              {props.arrowNext || (
                <Icon
                  icon="arrow-right"
                  color={colors["orca-grey-8"].hex}
                  title=""
                  strokeWidth={2}
                  width={15}
                  height={15}
                />
              )}
            </button>
          </div>
        )}
        renderArrowPrev={(clickHandler, hasPrev, label) => (
          <div
            className={`${
              carouselIndex === 0 && !props.infiniteLoop ? "disabled" : ""
            } control control-prev`}
          >
            <button onClick={clickHandler} ref={prevArrowButton}>
              {props.arrowPrev || (
                <Icon
                  icon="arrow-left"
                  color={colors["orca-grey-8"].hex}
                  strokeWidth={2}
                  title=""
                  width={15}
                  height={15}
                />
              )}
            </button>
          </div>
        )}
        renderIndicator={
          props.showIndicators
            ? (clickHandler, isSelected, index, label) => {
                return (
                  <button
                    className={`indicator-button${isSelected ? " active" : ""}`}
                    aria-label={`Click to view ${
                      props.items[index].title
                        ? `${props.items[index].title}`
                        : `Slide ${index + 1}`
                    }`}
                    onClick={clickHandler}
                  >
                    {props.indicator || <div className="indicator"></div>}
                  </button>
                )
              }
            : null
        }
      >
        {props.items.map(({ item }, i) => {
          return (
            <React.Fragment key={`carousel-item-${i}`}>{item}</React.Fragment>
          )
        })}
      </ReactResponsiveCarousel>
      {props.showMobileArrowControls && (
        <>
          <StyledMobileArrowControls className="mobile-arrow-controls">
            <StyledMobileArrowControl
              className={`${
                carouselIndex === 0 ? "disabled" : ""
              } mobile-arrow-control-left`}
              onClick={() => {
                // Only trigger click if button is not disabled
                if (!props.infiniteLoop && carouselIndex === 0) return
                prevArrowButton.current.click()
                setTimeout(() => {
                  // carousel.current.scrollIntoView({ block: "center" })
                  if (window.scrollY > carousel.current.offsetTop)
                    window.scrollTo(
                      0,
                      carousel.current.offsetTop - props.mobileScrollOffset
                    )
                }, 350)
              }}
            >
              <Icon
                icon="chevron-left"
                color={colors["orca-grey-8"].hex}
                strokeWidth={2}
                title=""
                width={15}
                height={15}
              />{" "}
              Back
            </StyledMobileArrowControl>
            <StyledMobileArrowControl
              className={`${
                carouselIndex === props.items.length - 1 && !props.infiniteLoop
                  ? "disabled"
                  : ""
              } mobile-arrow-control-right`}
              onClick={() => {
                // Only trigger click if button is not disabled
                if (
                  !props.infiniteLoop &&
                  carouselIndex === props.items.length - 1
                )
                  return
                nextArrowButton.current.click()
                setTimeout(() => {
                  if (window.scrollY > carousel.current.offsetTop)
                    window.scrollTo(
                      0,
                      carousel.current.offsetTop - props.mobileScrollOffset
                    )
                }, 350)
              }}
            >
              Next
              <Icon
                icon="chevron-right"
                color={colors["orca-grey-8"].hex}
                strokeWidth={2}
                title=""
                width={15}
                height={15}
              />
            </StyledMobileArrowControl>
          </StyledMobileArrowControls>
        </>
      )}
    </StyledComponentContainer>
  )
}

Carousel.propTypes = {
  onChange: PropTypes.func,
  showArrowsOnMobileDevice: PropTypes.bool,
  showMobileArrowControls: PropTypes.bool,
  /** How far up from the top of the carousel should the page scroll (when clicking on mobile arrow controls) */
  mobileScrollOffset: PropTypes.number,
  /** Next arrow icon */
  arrowNext: PropTypes.node,
  /** Prev arrow icon */
  arrowPrev: PropTypes.node,
  /** Indicator showing what slide is currently active */
  indicator: PropTypes.node,
  showStatus: PropTypes.bool,
  autoPlay: PropTypes.bool,
  swipeable: PropTypes.bool,
  emulateTouch: PropTypes.bool,
  infiniteLoop: PropTypes.bool,
  interval: PropTypes.number,
  showThumbs: PropTypes.bool,
  /** Active carousel slide */
  selectedItem: PropTypes.bool,
  dynamicHeight: PropTypes.bool,
  items: PropTypes.array,
  showIndicators: PropTypes.bool,
}

Carousel.defaultProps = {
  showStatus: false,
  autoPlay: true,
  swipeable: true,
  dynamicHeight: false,
  emulateTouch: true,
  infiniteLoop: true,
  interval: 6000,
  showIndicators: true,
  showThumbs: false,
  showArrowsOnMobileDevice: true,
  mobileScrollOffset: 25,
}

export default Carousel
