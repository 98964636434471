// Packages
import PropTypes from "prop-types"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

// Main Component
export default function BackgroundImage(props) {
  return (
    <GatsbyImage
      image={props.image}
      className={`background ${props.className ? props.className : ""} `}
      style={{
        position: "absolute",
        zIndex: "-10",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
        filter: `brightness(${props.brightness})`,
      }}
      objectPosition={props.objectPosition}
      alt={props.alt || ""}
    />
  )
}

// Prop Types
BackgroundImage.propTypes = {
  /**
   * Sets brightness of image
   */
  brightness: PropTypes.string,
  /**
   * Sets object position of image
   */
  objectPosition: PropTypes.string,
  /**
   * Object containing fluid properties of image (gatsby image)
   */
  image: PropTypes.shape().isRequired,
  /** Additional class name(s) */
  className: PropTypes.string,
  /** Alt text */
  alt: PropTypes.string,
}

BackgroundImage.defaultProps = {
  objectPosition: "50% 50%",
}
