// Packages
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// Components
import { Link as GatsbyLink } from "gatsby"

// Utils
import { getMediaQueries } from "@gray-fox-components/utils/utils"
import "@gray-fox-components/utils/_variables.css"
const { canHover } = getMediaQueries()

// Styles
const ButtonContainer = styled.a`
  display: flex;
  background: ${props => props.$defaultBackground};
  border-radius: 4px;
  cursor: pointer;
  padding: 11.5px 14px;
  border: 3px solid
    ${props => props.$defaultBorderColor || props.$defaultBackground};
  max-width: 100%;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  span {
    color: ${props => props.$defaultColor};
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    text-overflow: ellipsis;
  }
  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
      hue-rotate(149deg) brightness(106%) contrast(102%);
  }

  ${canHover`
    &:hover {
      background: ${props => props.$activeBackground};
      border: 1px solid
        ${props => props.$activeBorderColor || props.$activeBackground};
      text-decoration: none;
      span {
        color: ${props => props.$activeColor};
      }
    }
  `}

  &.primary {
    --default-background: var(--primary-colors-dark-navy-blue-hex);
    --default-color: var(--white-and-black-white-hex);
    --hover-background: var(--primary-colors-sunset-hex);
    --active-background: var(--accent-sunset-8-hex);
    background: ${props =>
      props.$defaultBackground || "var(--default-background)"};
    border: 3px solid
      ${props => props.$defaultBorderColor || "var(--default-background)"};
    span {
      color: ${props => props.$defaultColor || "var(--default-color)"};
    }

    ${canHover`
      &:hover {
        background: ${props =>
          props.$hoverBackground || "var(--hover-background)"};
        border-color: ${props =>
          props.$hoverBorderColor || "var(--hover-background)"};
        span {
          color: ${props => props.$hoverColor || "var(--default-color)"};
        }
      }
    `}
    &:active {
      background: ${props =>
        props.$activeBackground || "var(--active-background)"};
      border-color: ${props =>
        props.$activeBorderColor || "var(--active-background)"};
      span {
        color: ${props => props.$activeColor || "var(--default-color)"};
      }
    }
    &:focus {
      background: ${props =>
        props.$focusBackground || "var(--default-background)"};
      border-color: ${props =>
        props.$focusBorderColor || "var(--default-background)"};
      span {
        color: ${props => props.$focusColor || "var(--default-color)"};
      }
    }
  }
  &.secondary {
    --default-background: var(--white-and-black-white-hex);
    --default-color: var(--primary-darks-dark-navy-blue-hex);
    --hover-color: var(--primary-colors-sunset-hex);
    --active-color: var(--accent-sunset-8-hex);
    --focus-border: 2px solid
      ${props => props.$focusBorderColor || "var(--white-and-black-black-hex)"};
    background: ${props =>
      props.$defaultBackground || "var(--default-background)"};
    border: 3px solid
      ${props => props.$defaultBorderColor || "var(--default-color)"};
    span {
      color: ${props => props.$defaultColor || "var(--default-color)"};
    }
    ${canHover`
      &:hover {
        background: ${props =>
          props.$hoverBackground || "var(--default-background)"};
        border-color: ${props =>
          props.$hoverBorderColor || "var(--hover-color)"};
        span {
          color: ${props => props.$hoverColor || "var(--hover-color)"};
        }
      }
    `}
    &:active {
      background: ${props =>
        props.$activeBackground || "var(--default-background)"};
      border-color: ${props =>
        props.$activeBorderColor || "var(--active-color)"};
      span {
        color: ${props => props.$activeColor || "var(--active-color)"};
      }
    }
    &:focus {
      background: ${props =>
        props.$focusBackground || "var(--default-background)"};
      border: var(--focus-border);
      span {
        color: ${props => props.$focusColor || "var(--default-color)"};
      }
    }
  }
  &.tertiary {
    --default-background: var(--primary-darks-5-hex);
    --default-color: var(--white-and-black-white-hex);
    --hover-background: var(--primary-darks-logo-text-navy-blue-hex);
    --active-background: var(--accent-sunset-8-hex);
    --focus-border: 2px solid
      ${props => props.$focusBorderColor || "var(--white-and-black-black)"};
    background: ${props =>
      props.$defaultBackground || "var(--default-background)"};
    border: 3px solid
      ${props => props.$defaultBorderColor || "var(--default-background)"};
    span {
      color: ${props => props.$defaultColor || "var(--default-color)"};
    }
    ${canHover`
      &:hover {
        background: ${props =>
          props.$hoverBackground || "var(--hover-background)"};
        border-color: ${props =>
          props.$hoverBorderColor || "var(--hover-background)"};
        span {
          color: ${props => props.$hoverColor || "var(--default-color)"};
        }
      }
    `}
    &:active {
      background: ${props =>
        props.$activeBackground || "var(--active-background)"};
      border-color: ${props =>
        props.$activeBorderColor || "var(--active-background)"};
      span {
        color: ${props => props.$activeColor || "var(--default-color)"};
      }
    }
    &:focus {
      background: ${props =>
        props.$focusBackground || "var(--default-background)"};
      border-color: ${props =>
        props.$focusBorderColor || "var(--default-background)"};
      span {
        color: ${props => props.$focusColor || "var(--default-color)"};
      }
    }
  }
  &.quaternary {
    --default-background: var(--white-and-black-white-hex);
    --default-color: var(--primary-colors-dark-navy-blue-hex);
    --hover-color: var(--primary-colors-sunset-hex);
    --active-colors: var(--accent-sunset-8-hex);
    --focus-border: 2px solid
      ${props => props.$focusBorderColor || "var(--white-and-black-black)"};
    background: ${props =>
      props.$defaultBackground || "var(--default-background)"};
    border: 3px solid
      ${props => props.$defaultBorderColor || "var(--default-background)"};
    span {
      color: ${props => props.$defaultColor || "var(--default-color)"};
    }
    ${canHover`
      &:hover {
        background: ${props =>
          props.$hoverBackground || "var(--default-background)"};
        border-color: ${props =>
          props.$hoverBorderColor || "var(--default-background)"};
        span {
          color: ${props => props.$hoverColor || "var(--hover-color)"};
        }
      }
    `}
    &:active {
      background: ${props =>
        props.$activeBackground || "var(--default-background)"};
      border-color: ${props =>
        props.$activeBorderColor || "var(--default-background)"};
      span {
        color: ${props => props.$activeColor || "var(--active-color)"};
      }
    }
    &:focus {
      background: ${props =>
        props.$focusBackground || "var(--default-background)"};
      border: var(--focus-border);
      span {
        color: ${props => props.$focusColor || "var(--default-color)"};
      }
    }
    &.disabled,
    &[disabled] {
      background: var(--default-background);
    }
  }
  &.disabled,
  &[disabled] {
    pointer-events: none;
    background: var(--primary-lights-2-hex);
    border: none;
    span {
      color: var(--primary-lights-light-navy-blue-hex);
    }
  }
`

const ButtonContentsContainer = styled.div`
  display: flex;
  column-gap: ${props => props.columnGap || "10px"};
  justify-content: center;
  margin: 0 auto;
`

function Button(props) {
  return (
    <>
      <ButtonContainer
        {...props.functions}
        className={`${props.type ? props.type : ""} ${
          props.disabled ? "disabled" : ""
        } button ${props.className && props.className}`}
        {...props.buttonAttributes}
        $activeBackground={props.activeBackground}
        $activeColor={props.activeColor}
        $circleDefaultColor={props.circleDefaultColor}
        $circleActiveColor={props.circleActiveColor}
        $defaultBackground={props.defaultBackground}
        $defaultColor={props.defaultColor}
        $defaultBorderColor={props.defaultBorderColor}
        $activeBorderColor={props.activeBorderColor}
        $iconActiveColor={props.iconActiveColor}
        $borderDefaultColor={props.borderDefaultColor}
        $borderActiveColor={props.borderActiveColor}
        $iconBorderDefaultColor={props.iconBorderDefaultColor}
        $iconBorderActiveColor={props.iconBorderActiveColor}
        {...props.linkAttributes}
        as={
          props.linkAttributes
            ? props.linkAttributes.href
              ? "a"
              : GatsbyLink
            : "button"
        }
      >
        <ButtonContentsContainer columnGap={props.columnGap}>
          {props.icon && props.iconPlacement === "left" && props.icon}
          {props.image &&
            props.imagePlacement ===
              "left"(
                <img
                  src={props.image}
                  alt={props.imageAltText || "button icon"}
                />
              )}
          <span>{props.text}</span>
          {props.icon && props.iconPlacement === "right" && props.icon}
          {props.image &&
            props.imagePlacement ===
              "right"(
                <img
                  src={props.image}
                  alt={props.imageAltText || "button icon"}
                />
              )}
        </ButtonContentsContainer>
      </ButtonContainer>
    </>
  )
}

export default Button

Button.propTypes = {
  /**
   * Background color of button when button is active (clicked / hovered / pressed)
   */
  activeBackground: PropTypes.string,
  /**
   * Text color of button when button is active (clicked / hovered / pressed)
   */
  activeColor: PropTypes.string,
  /**
   * Button text
   */
  text: PropTypes.string.isRequired,
  /**
   * Default border color of button
   */
  defaultBorderColor: PropTypes.string,
  /**
   * Border color of button when button is active (clicked / hovered / pressed)
   */
  activeBorderColor: PropTypes.string,
  /**
   * Default background color of button
   */
  defaultBackground: PropTypes.string,
  /**
   * Sets default color of icon circle
   */
  circleDefaultColor: PropTypes.string,
  /**
   * Sets active color of icon circle
   */
  circleActiveColor: PropTypes.string,
  /**
   * Default text color of button
   */
  defaultColor: PropTypes.string,
  /**
   * Is button disabled?
   */
  disabled: PropTypes.bool,
  /**
   * Sets active color of icon
   */
  iconActiveColor: PropTypes.string,
  /**
   * Sets default color of border around icon circle
   */
  borderDefaultColor: PropTypes.string,
  /**
   * Sets active color of border around icon circle
   */
  borderActiveColor: PropTypes.string,
  /**
   * Button icon (should be GenericIcon)
   */
  icon: PropTypes.element,
  /**
   * Button image (src)
   */
  image: PropTypes.string,
  /**
   * Where should the image be placed? (right or left)
   */
  imagePlacement: PropTypes.oneOf(["left", "right"]),
  /**
   * Where should the icon be placed? (right or left)
   */
  iconPlacement: PropTypes.oneOf(["left", "right"]),
  /**
   * Alt text for button image
   */
  imageAltText: PropTypes.string,
  /**
   * Button Type
   */
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "custom",
  ]),
  /**
   * Custom class name of button
   */
  className: PropTypes.string,
  /**
   * Button Functions, e.g. { onClick: () => {}}
   */
  functions: PropTypes.shape(),
  /**
   * Link Attributes e.g. { to: "/", target: "_blank"}
   */
  linkAttributes: PropTypes.shape(),
  /**
   * Button Attributes e.g. { type: "submit"}
   */
  buttonAttributes: PropTypes.shape(),
  /**
   * Sets default color of icon border
   */
  iconBorderDefaultColor: PropTypes.string,
  /**
   * Sets active color of icon border
   */
  iconBorderActiveColor: PropTypes.string,
  /**
   * Gap between elements inside button (e.g. 10px)
   */
  columnGap: PropTypes.string,
}

Button.defaultProps = {
  iconPlacement: "right",
  imagePlacement: "right",
  tab: "new",
  type: "custom",
}
