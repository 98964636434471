// Packages
import React from "react"
import PropTypes from "prop-types"

// Images
import OGImage from "@images/og/gray-fox-og.jpg"

// Main Component
function SEO(props) {
  return (
    <>
      <html lang={props.lang} />
      {props.title && <title>{props.title}</title>}
      {props.description && (
        <meta name="description" content={props.description} />
      )}
      {(props.ogTitle || props.title) && (
        <meta property="og:title" content={props.ogTitle || props.title} />
      )}
      {(props.ogDescription || props.description) && (
        <meta
          property="og:description"
          content={props.ogDescription || props.description}
        />
      )}
      <meta property="og:type" content={props.ogType} />
      {props.slug && (
        <meta
          property="og:url"
          content={`${props.domain}/${
            props.slug === "index" ? "" : `${props.slug}/`
          }`}
        />
      )}
      {props.ogImage !== null && (
        <>
          <meta property="og:image" content={props.ogImage} />
          <meta property="og:image:width" content={props.ogImageWidth} />
          <meta property="og:image:height" content={props.ogImageHeight} />
        </>
      )}
      {props.canonicalSlug && (
        <>
          <link
            rel="canonical"
            href={`${props.domain}/${props.canonicalSlug}/`}
          />
        </>
      )}
      {props.hidden && <meta name="robots" content="none" />}
      {props.children}
    </>
  )
}

export default SEO

// Prop Types
SEO.propTypes = {
  /** If there is a duplicate version of this page
   * and this is not the canonical version, include the canonicalSlug prop
   */
  canonicalSlug: PropTypes.string,
  /** Slug of the page */
  slug: PropTypes.string,
  children: PropTypes.node,
  /** Description of the page */
  description: PropTypes.string,
  /** OG description of the page
   * If OG description is the same as description, there is no need to add OG description prop
   */
  ogDescription: PropTypes.string,
  /** OG image of the page */
  ogImage: PropTypes.string,
  ogImageWidth: PropTypes.string,
  ogImageHeight: PropTypes.string,
  /** Title of the page */
  title: PropTypes.string,
  /** OG Title of the page
   * If OG title is the same as title, there is no need to add OG title prop
   */
  ogTitle: PropTypes.string,
  /** OG type of the page (website, article, etc) */
  ogType: PropTypes.string,
  /** Should this page be hidden? (not indexed on Google) */
  hidden: PropTypes.bool,
  /** Language of the page */
  lang: PropTypes.string,
  domain: PropTypes.string,
}

SEO.defaultProps = {
  domain: `https://www.grayfoxmortgage.com`,
  hidden: false,
  lang: "en",
  ogImage: OGImage,
  ogImageWidth: `1200`,
  ogImageHeight: `630`,
  ogType: `website`,
}
