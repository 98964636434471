// Packages
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

// Components
import Licensing from "@gray-fox-components/components/mb/licensing"
import DefaultHeadshot from "@components/default-headshot"

// Utils
import { getMediaQueries } from "@gray-fox-components/utils/utils"

// Styles
const { canHover } = getMediaQueries()
const StyledComponentContainer = styled.button`
  --headshot-size: 100px;
  background: var(--primary-lights-1-hex);
  border: 0;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 3px solid var(--primary-colors-light-navy-blue-hex);
  column-gap: 10px;
  display: flex;
  max-width: 327px;
  padding: 10px;
  text-align: left;
  transition: all 0.2s ease-in-out;
  .gatsby-image-wrapper {
    height: var(--headshot-size);
    width: var(--headshot-size);
  }
  ${canHover`
    &:hover {
      background: var(--primary-lights-1-hex);
      border-color: var(--primary-colors-sunset-hex);
    }
  `}
  &:focus, &.selected {
    background: var(--white-and-black-3-hex);
    border-color: var(--primary-colors-logo-text-navy-blue-hex);
  }
`
const StyledInfo = styled.div``

// Main Component
function MBCard(props) {
  return (
    <StyledComponentContainer
      onClick={() => props.selectBanker(props)}
      className={`${
        props.slug === props.selectedBanker?.slug ? "selected" : ""
      }`}
    >
      {props.headshot ? (
        <GatsbyImage
          className="layout__img--rounded"
          image={props.headshot}
          alt={props.name}
        />
      ) : (
        <DefaultHeadshot {...props} />
      )}
      <StyledInfo>
        <p className="layout__caption">{props.name}</p>
        <p className="layout__helper">NMLS #{props.nmls}</p>
        <p className="layout__helper">
          <Licensing
            licenses={props.licenses}
            stateSpecificLicense={props.stateSpecificLicense}
          />
        </p>
      </StyledInfo>
    </StyledComponentContainer>
  )
}

// Prop Types
MBCard.propTypes = {
  selectBanker: PropTypes.func,
  /** Gatsby Image data */
  headshot: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  nmls: PropTypes.number.isRequired,
  licenses: PropTypes.array.isRequired,
  stateSpecificLicense: PropTypes.string.isRequired,
}

export default MBCard
