// Packages
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Apply, Footer, Modal, Nav } from "@gray-fox-components"

// Utils
import { allBankersData, originators } from "@utils/mb"

// Styles
const StyledComponentContainer = styled.div``

// Main Component
function Layout(props) {
  const [navIsOpen, setNavOpen] = useState(false)
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false)
  const bankerData = allBankersData.find(
    ({ slug }) => slug && slug === props.pageContext.slug
  )

  useEffect(() => {
    setIsApplyModalOpen(prevIsApplyModal => {
      if (prevIsApplyModal) return false
      else return prevIsApplyModal
    })
    setNavOpen(navIsOpen => {
      if (navIsOpen) return false
      else return navIsOpen
    })
  }, [props.location])
  return (
    <>
      <StyledComponentContainer>
        <Nav
          navIsOpen={navIsOpen}
          setNavOpen={setNavOpen}
          setIsApplyModalOpen={setIsApplyModalOpen}
          bankerData={bankerData}
        />
        <main>
          {React.cloneElement(props.children, {
            ...props,
            bankerData,
            setIsApplyModalOpen,
          })}
        </main>
        <Footer bankerData={bankerData} />
      </StyledComponentContainer>
      <Modal isModalOpen={isApplyModalOpen}>
        <Apply
          closeModal={() => setIsApplyModalOpen(false)}
          bankers={originators}
        />
      </Modal>
    </>
  )
}

// Prop Types
Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default Layout
