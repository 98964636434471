import { css } from "styled-components"

export const colors = {
  // Primary
  "primary-colors-dark-navy-blue": {
    hex: "#2D3748",
  },
  "primary-colors-logo-text-navy-blue": {
    hex: "#2F4E6F",
  },
  "primary-darks-5": {
    hex: "#4a7aae",
  },
  "arctic-blue": {
    hex: "#0b395b",
    rgb: "11, 57, 91",
  },
  "arctic-blue-1": {
    hex: "#f5fafd",
    rgb: "245, 250, 253",
  },
  "arctic-blue-2": {
    hex: "#e1effa",
    rgb: "225, 239, 250",
  },
  "arctic-blue-4": {
    hex: "#022946",
    rgb: "2, 41, 70",
  },
  "atlantic-blue": {
    hex: "#25b5f3",
    rgb: "37, 181, 243",
  },
  "atlantic-blue-1": {
    hex: "#f5fcff",
    rgb: "245, 252, 255",
  },
  "atlantic-blue-2": {
    hex: "#e2f5fd",
    rgb: "226, 245, 253",
  },
  "atlantic-blue-3": {
    hex: "#9edefa",
    rgb: "158, 222, 250",
  },
  "atlantic-blue-4": {
    hex: "#6ecef7",
    rgb: "110, 206, 247",
  },
  "atlantic-blue-6": {
    hex: "#007ab4",
    rgb: "0, 122, 180",
  },
  "baltic-blue": {
    hex: "#031d30",
    rgb: "3, 29, 48",
  },
  "pacific-blue": {
    hex: "#0274e8",
    rgb: "2, 116, 232",
  },
  "pacific-blue-1": {
    hex: "#b1d8fe",
    rgb: "177, 216, 254",
  },
  "pacific-blue-2": {
    hex: "#3c9dfd",
    rgb: "60, 157, 253",
  },
  "pacific-blue-4": {
    hex: "#013a74",
    rgb: "1, 58, 116",
  },
  "pacific-blue-5": {
    hex: "#012a53",
    rgb: "1, 42, 83",
  },
  // Secondary
  "horizon-yellow": {
    hex: "#ffd53e",
    rgb: "255, 213, 62",
  },
  "horizon-yellow-1": {
    hex: "#fefcf6",
    rgb: "254, 252, 246",
  },
  "horizon-yellow-2": {
    hex: "#ffefb4",
    rgb: "255, 239, 180",
  },
  "horizon-yellow-3": {
    hex: "#ffea9d",
    rgb: "255, 234, 157",
  },
  "horizon-yellow-4": {
    hex: "#ffdc60",
    rgb: "255, 220, 96",
  },
  "horizon-yellow-6": {
    hex: "#fdc600",
    rgb: "253, 198, 0",
  },
  "palm-green": {
    hex: "#1cc0a5",
    rgb: "28, 192, 165",
  },
  "palm-green-1": {
    hex: "#e8faf6",
    rgb: "232, 250, 246",
  },
  "palm-green-2": {
    hex: "#d5f6ee",
    rgb: "213, 246, 238",
  },
  "palm-green-3": {
    hex: "#60e9c9",
    rgb: "96, 233, 201",
  },
  "palm-green-5": {
    hex: "#077577",
    rgb: "7, 117, 119",
  },
  "palm-green-6": {
    hex: "#055461",
    rgb: "5, 84, 97",
  },
  "sunset-orange": {
    hex: "#e86143",
    rgb: "232, 97, 67",
  },
  "sunset-orange-1": {
    hex: "#fef9ef",
    rgb: "254, 249, 239",
  },
  "sunset-orange-2": {
    hex: "#ffcc9f",
    rgb: "255, 204, 159",
  },
  "sunset-orange-3": {
    hex: "#ffa979",
    rgb: "255, 169, 121",
  },
  "sunset-orange-4": {
    hex: "#ff8255",
    rgb: "255, 130, 85",
  },
  "sunset-orange-6": {
    hex: "#bd432b",
    rgb: "189, 67, 43",
  },
  "thistle-purple": {
    hex: "#8453e6",
    rgb: "132, 83, 230",
  },
  "thistle-purple-1": {
    hex: "#f4effd",
    rgb: "244, 239, 253",
  },
  "thistle-purple-2": {
    hex: "#d2c1f6",
    rgb: "210, 193, 246",
  },
  "thistle-purple-3": {
    hex: "#a682ed",
    rgb: "166, 130, 237",
  },
  "thistle-purple-5": {
    hex: "#6325e0",
    rgb: "99, 37, 224",
  },
  "thistle-purple-6": {
    hex: "#3f1592",
    rgb: "63, 21, 146",
  },
  // Grey
  "ice-grey-1": {
    hex: "#f7fafc",
    rgb: "247, 250, 252",
  },
  "grey-2": {
    hex: "#edf2f7",
    rgb: "237, 242, 247",
  },
  "grey-3": {
    hex: "#e2e8f0",
    rgb: "226, 232, 240",
  },
  "grey-4": {
    hex: "#cbd2dd",
    rgb: "203, 210, 221",
  },
  "bottlenose-grey-5": {
    hex: "#75808f",
    rgb: "117, 128, 143",
  },
  "dusky-grey-6": {
    hex: "#5c6677",
    rgb: "92, 102, 119",
  },
  "pilot-grey-7": {
    hex: "#2d3748",
    rgb: "45, 55, 72",
  },
  "orca-grey-8": {
    hex: "#1a202c",
    rgb: "26, 32, 44",
  },
}

// Phone utils
export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3]
  }
  return phoneNumberString
}

export const getPhoneNumbers = contactInfo => {
  if (!contactInfo) return
  let mobilePhone = contactInfo?.mobile?.replace(/\D/g, ""),
    officePhone = contactInfo?.office?.replace(/\D/g, "")

  let primaryPhone,
    primaryPhoneText,
    altPhone,
    altPhoneText,
    mobilePhoneText = formatPhoneNumber(contactInfo?.mobile),
    officePhoneText = formatPhoneNumber(contactInfo?.office)

  if (contactInfo?.extension) {
    officePhone = `${officePhone};${contactInfo?.extension.replace(/\D/g, "")}`
    officePhoneText = `${officePhoneText} ext. ${contactInfo?.extension}`
  }
  if (contactInfo?.favoredPhone === "mobile") {
    primaryPhone = mobilePhone
    primaryPhoneText = mobilePhoneText
    if (contactInfo?.office) {
      altPhone = officePhone
      altPhoneText = officePhoneText
    }
  } else if (contactInfo?.favoredPhone === "office") {
    primaryPhone = officePhone
    primaryPhoneText = officePhoneText
    if (contactInfo?.mobile) {
      altPhone = mobilePhone
      altPhoneText = mobilePhoneText
    }
  }
  return {
    altPhone,
    altPhoneText,
    primaryPhone,
    primaryPhoneText,
    officePhone,
    officePhoneText,
    mobilePhone,
    mobilePhoneText,
  }
}

// @Media
export const mediaBreakpoints = {
  tablet: 950,
  mobile: 600,
  desktop: 1439,
}

export function getMediaQueries(size = mediaBreakpoints) {
  const above = Object.keys(size).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${size[label] + 1}px) {
        ${css(...args)};
      }
    `
    return acc
  }, {})
  const below = Object.keys(size).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (max-width: ${size[label]}px) {
        ${css(...args)};
      }
    `
    return acc
  }, {})
  // Device supports hover
  const canHover = (...args) => css`
    @media (hover: hover) {
      ${css(...args)}
    }
  `
  return {
    above,
    below,
    canHover,
  }
}

const utils = {
  colors,
  mediaBreakpoints,
  getMediaQueries,
  getPhoneNumbers,
}

export default utils
