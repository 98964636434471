// Packages
import React, { useState } from "react"
import { renderToString } from "react-dom/server"
import PropTypes from "prop-types"
import styled from "styled-components"
import Cleave from "cleave.js/react"
import "cleave.js/dist/addons/cleave-phone.us"
import Select from "react-select"

// Components
import FormIcon from "@gray-fox-components/components/icons/form"
import Icon from "@gray-fox-components/components/icons/icon"

// Utils
import { colors, getMediaQueries } from "@gray-fox-components/utils/utils"

// Styles
const { below, canHover } = getMediaQueries()

const FieldGroup = styled.div`
  * {
    font-size: 16px;
    line-height: 20px;
    outline: none;
    transition: all 0.2s ease-in-out;
    &:not(.errors):not([disabled]):not(button):focus {
      border: 1px solid var(--primary-colors-dark-navy-blue-hex);
    }
    &.errors {
      border-color: var(--accent-sunset-8-hex) !important;
    }
  }
  label {
    z-index: ${props => !props.showLabel && -1};
  }
`

const IconContainer = styled.div`
  background: transparent;
  border: none;
  display: flex;
  left: ${props => props.iconPlacement === "left" && "12px"};
  right: ${props => props.iconPlacement === "right" && "12px"};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  &:hover ~ input {
    :not([disabled]):not(:focus) {
      border-color: var(--white-and-black-black-hex);
    }
  }
  ${canHover`
    :hover ~ input {
      :not([disabled]):hover {
        border-color: var(--white-and-black-black-hex);
      }
    }
  `}
`

const InputGroup = styled(FieldGroup)`
  position: relative;
  width: 100%;
  input {
    border-radius: 4px;
    border: 1px solid var(--white-and-black-5-hex);
    box-sizing: border-box;
    display: block;
    height: 64px;
    margin-bottom: 4px;
    padding: 0px 12px;
    padding-top: ${props => !props.isFieldEmpty && props.showLabel && "12px"};
    width: 100%;
    &::placeholder {
      color: var(--white-and-black-7-hex);
      transition-delay: 0.1s;
    }
    &[disabled] {
      border-color: var(--white-and-black-3-hex);
      color: var(--white-and-black-7-hex);
      &::placeholder {
        color: var(--white-and-black-3-hex);
      }
      & ~ label {
        color: var(--white-and-black-3-hex);
      }
    }
    &:focus {
      color: var(--white-and-black-black-hex);
      padding-top: ${props => props.showLabel && "12px"};
      &::placeholder {
        opacity: 0;
        transition-delay: 0s;
      }
      & ~ label {
        opacity: 1;
        transition-delay: 0.1s;
      }
    }

    &.icon {
      padding-left: ${props =>
        props.iconPlacement === "left" ? `calc(12px + 20px + 8px)` : "12px"};
      & + label {
        left: ${props =>
          props.iconPlacement === "left" ? "calc(12px + 20px + 12px)" : "12px"};
      }
    }
    ${canHover`
      :not([disabled]):hover {
        border-color: var(--white-and-black-black-hex);
      }
    `}
  }

  label {
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 14px;
    line-height: 16px;
    color: var(--white-and-black-7-hex);
    opacity: ${props => (props.isFieldEmpty || !props.showLabel ? 0 : 1)};
  }
`

const Rosie = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`

const SelectGroup = styled.div`
  position: relative;
  width: 100%;
  label {
    color: var(--white-and-black-7-hex);
    font-size: 14px;
    left: 12px;
    line-height: 16px;
    opacity: ${props =>
      (!props.isFieldEmpty && props.showLabel) ||
      (props.isSelectFocused && props.showLabel)
        ? 1
        : 0};
    position: absolute;
    top: 12px;
    transition-delay: 0s;
  }
  .select__indicator-separator {
    display: none;
  }
  .select__option {
    color: var(--white-and-black-black-hex);
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    &--is-focused {
      background: var(--primary-darks-1-hex);
    }
    &--is-selected {
      background: var(--primary-darks-2-hex);
    }
    ${canHover`
      &:not(&--is-selected):hover {
        background: var(--primary-darks-1-hex);
      }
    `}
  }
  .select__value-container {
    padding: 0;
    * {
      margin: 0;
    }
  }
  select,
  .select__control {
    background-color: #fff;
    border: 0.5px solid
      ${props =>
        props.isSelectFocused
          ? "var(--primary-colors-dark-navy-blue-hex)"
          : "var(--white-and-black-5-hex)"};
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
    color: var(--white-and-black-7-hex);
    cursor: pointer;
    font-size: 16px;
    height: 64px;
    line-height: 20px;
    margin: 0;
    outline: none;
    padding: ${props =>
      !props.isFieldEmpty || props.isSelectFocused ? "22px 12px 12px" : "12px"};
    width: 100%;
    &[disabled],
    &--is-disabled {
      border-color: var(--white-and-black-3-hex);
    }
    ${canHover`
      :not([disabled]):hover {
        border-color: ${props =>
          !props.isSelectFocused
            ? "var(--white-and-black-black-hex)"
            : "var(--primary-colors-dark-navy-blue-hex)"};
      }
    `}
  }
  .select {
    margin-bottom: 4px;
    &:focus-within {
      color: var(--white-and-black-black-hex);
      input::placeholder {
        transition-delay: 0s;
      }
      & ~ label {
        transition-delay: 100ms;
      }
    }
    &.errors {
      .select__control {
        border-color: var(--accent-sunset-8-hex) !important;
      }
    }
  }
`

const TextAreaGroup = styled(FieldGroup)`
  position: relative;
  width: 100%;
  label {
    color: var(--white-and-black-7-hex);
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 12px;
    left: 12px;
    opacity: ${props => (props.isFieldEmpty || !props.showLabel ? 0 : 1)};
  }
  textarea {
    border: 1px solid var(--white-and-black-5-hex);
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--white-and-black-7-hex);
    font-size: 16px;
    height: 96px;
    line-height: 20px;
    padding: 8px 12px;
    padding-top: ${props =>
      !props.isFieldEmpty && props.showLabel && "calc(12px + 16px)"};
    resize: none;
    width: 100%;
    &::placeholder {
      color: var(--white-and-black-7-hex);
      font-size: 16px;
      line-height: 20px;
      opacity: 1;
    }
    :focus {
      color: var(--white-and-black-black-hex);
      padding-top: ${props => props.showLabel && "calc(12px + 16px)"};
      &::placeholder {
        opacity: 0;
        transition-delay: 0s;
      }
      & ~ label {
        opacity: 1;
        transition-delay: 0.1s;
      }
    }
    :not([disabled]):hover {
      border-color: var(--white-and-black-black-hex);
    }
    &[disabled] {
      background: #fff;
      border-color: var(--white-and-black-3-hex);
      ::placeholder {
        color: var(--white-and-black-3-hex);
      }
    }
  }
  .message-container {
    display: flex;
    justify-content: flex-end;
    &.errors {
      justify-content: space-between;
    }
    .character-limit {
      color: var(--white-and-black-7-hex);
      font-size: 12px;
      line-height: 20px;
      justify-self: end;
      &.errors {
        color: var(--accent-sunset-8-hex);
      }
    }
  }
`

const RadioGroup = styled.div`
  display: flex;
`

const RadioLabel = styled.label`
  --line-height: 25px;
  display: inline-block;
  font-size: 16px;
  line-height: var(--line-height);
  // 16px is width of input image, 8px is space between image and label
  padding-inline-start: calc(16px + 8px);
  opacity: 1 !important;
  pointer-events: initial !important;
  position: relative !important;
  text-transform: initial !important;
  &::before {
    content: "";
    display: inline-block;
    height: 16px;
    left: 0px;
    position: absolute;
    top: calc(var(--line-height) / 2);
    transform: translateY(-50%);
    width: 16px;
  }
  &.errors {
    border-color: var(--accent-sunset-8-hex) !important;
  }
`

const RadioInput = styled.input`
  height: 0px;
  position: relative;
  pointer-events: initial;
  opacity: 0;
  width: 0;
  &:not([disabled]) {
    & + label::before {
      background-image: url(data:image/svg+xml,${encodeURIComponent(
        renderToString(
          <FormIcon
            icon={"unselected-radio"}
            borderColor={colors["dusky-grey-6"].hex}
          />
        )
      )});
    }
    &:checked + label {
      border: none;
      &::before {
        background-image: url(data:image/svg+xml,${encodeURIComponent(
          renderToString(<FormIcon icon={"selected-radio"} />)
        )});
      }
    }
    ${canHover`
      &:hover:not(:checked) + label::before {
        background-image: url(data:image/svg+xml,${encodeURIComponent(
          renderToString(
            <FormIcon
              icon={"unselected-radio"}
              borderColor={colors["orca-grey-8"].hex}
            />
          )
        )});
      }
    `}
  }
  &[disabled] + label {
    color: var(--white-and-black-3-hex);
    &::before {
      background-image: url(data:image/svg+xml,${encodeURIComponent(
        renderToString(
          <FormIcon
            icon={"disabled-radio"}
            borderColor={colors["grey-3"].hex}
          />
        )
      )});
    }
  }
  ${below.mobile`margin-bottom: 0px;`}
`
const CheckboxGroup = styled(RadioGroup)``
const CheckboxInput = styled(RadioInput)`
  &:not([disabled]) {
    & + label::before {
      background-image: url(data:image/svg+xml,${encodeURIComponent(
        renderToString(
          <FormIcon
            icon={"unselected-checkbox"}
            borderColor={colors["dusky-grey-6"].hex}
          />
        )
      )});
    }
    &:checked + label {
      border: none;
      color: var(--white-and-black-black-hex);
      &::before {
        background-image: url(data:image/svg+xml,${encodeURIComponent(
          renderToString(
            <FormIcon
              icon={"selected-checkbox"}
              borderColor={colors["orca-grey-8"].hex}
            />
          )
        )});
      }
    }
    ${canHover`
      &:hover:not(:checked) + label::before {
        content: "";
        background-image: url(data:image/svg+xml,${encodeURIComponent(
          renderToString(
            <FormIcon
              icon={"unselected-checkbox"}
              borderColor={colors["orca-grey-8"].hex}
            />
          )
        )});
      }
      &:hover + label {
        color: var(--white-and-black-black-hex);
      }
    `}
  }

  &[disabled] + label {
    color: var(--white-and-black-3-hex);
    &::before {
      background-image: url(data:image/svg+xml,${encodeURIComponent(
        renderToString(
          <FormIcon
            icon={"disabled-checkbox"}
            borderColor={colors["grey-3"].hex}
          />
        )
      )});
    }
  }
`

const ErrorMessage = styled.div`
  color: var(--accent-sunset-8-hex);
  font-size: 12px;
  line-height: 16px;
`

// Main Component
export default function OutlinedField({
  field,
  errors,
  type,
  name,
  id,
  placeholder,
  defaultValue,
  ref,
  className,
  labelText,
  errorMessage,
  register,
  inputType,
  control,
  Controller,
  rules,
  selectOptions,
  cols,
  rows,
  ariaLabel,
  value,
  iconName,
  iconPlacement,
  iconFunctions,
  iconTitle,
  disabled,
  characterLimit,
  watch,
  isSearchable,
  cleaveOptions,
  showLabel,
}) {
  const [isSelectFocused, setIsSelectFocused] = useState(null)
  const outlinedField = type => {
    let nameAttribute
    let idAttribute

    const inputIconFunctions = {
      onClick: () => {
        iconFunctions.onClick && iconFunctions.onClick()
      },
    }

    const selectIcon = (hasErrors, disabled) => (
      <IconContainer
        as={iconFunctions ? "button" : "div"}
        {...inputIconFunctions}
        type={iconFunctions && "button"}
        className="icon-container"
        iconPlacement={iconPlacement}
      >
        <Icon
          icon={iconName}
          color={
            hasErrors
              ? colors["sunset-orange-6"].hex
              : disabled
              ? colors["grey-3"].hex
              : colors["dusky-grey-6"].hex
          }
          width={24}
          height={24}
          title={iconTitle}
        />
      </IconContainer>
    )
    // Text fields
    if (field === "input") {
      if (type === "email") {
        nameAttribute = name || "email"
        idAttribute = id || "email"
        return (
          <>
            <InputGroup
              className={`${iconName && "icon"} ${className} outlined-field`}
              iconPlacement={iconPlacement}
              isFieldEmpty={
                (watch && watch(nameAttribute) === "") || !watch(nameAttribute)
              }
              showLabel={showLabel}
            >
              {iconName &&
                selectIcon(errors && errors[nameAttribute], disabled)}
              <input
                type={inputType || "email"}
                name={nameAttribute || "email"}
                id={idAttribute}
                placeholder={placeholder || "Email address"}
                defaultValue={defaultValue || ""}
                ref={ref}
                {...register(
                  nameAttribute || "email",
                  rules || {
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  }
                )}
                className={`${
                  errors && errors[nameAttribute] ? "errors" : undefined
                }
                  ${iconName && "icon"}
                  `}
              />
              <label htmlFor={idAttribute}>
                {labelText || placeholder || "Email Address"}
              </label>

              {errors && errors[nameAttribute] && (
                <ErrorMessage>
                  {errorMessage ||
                    `Oh, no! That doesn’t look like an email address. Could you try again?`}
                </ErrorMessage>
              )}
            </InputGroup>
          </>
        )
      } else if (type === "name") {
        nameAttribute = name || "name"
        idAttribute = name || "name"
        return (
          <>
            <InputGroup
              className={`${iconName && "icon"} ${className} outlined-field`}
              iconPlacement={iconPlacement}
              showLabel={showLabel}
              isFieldEmpty={
                (watch && watch(nameAttribute) === "") || !watch(nameAttribute)
              }
            >
              {iconName &&
                selectIcon(errors && errors[nameAttribute], disabled)}
              <input
                type={inputType || "text"}
                name={nameAttribute}
                id={idAttribute}
                placeholder={placeholder || "Full Name"}
                defaultValue={defaultValue || ""}
                ref={ref}
                {...register(
                  nameAttribute || "name",
                  rules || {
                    required: true,
                    minLength: 2,
                  }
                )}
                className={`${
                  errors && errors[nameAttribute] ? "errors" : undefined
                }
                    ${iconName && "icon"}
                    `}
              />
              <label htmlFor={idAttribute}>
                {labelText || placeholder || "Your name"}
              </label>
              {errors && errors[nameAttribute] && (
                <ErrorMessage>
                  {errorMessage || `Please enter your full name.`}
                </ErrorMessage>
              )}
            </InputGroup>
          </>
        )
      } else if (type === "phone") {
        nameAttribute = name || "phone"
        idAttribute = id || "phone"
        return (
          <>
            <InputGroup
              className={`${iconName && "icon"} ${className} outlined-field`}
              iconPlacement={iconPlacement}
              showLabel={showLabel}
              isFieldEmpty={
                (watch && watch(nameAttribute) === "") || !watch(nameAttribute)
              }
            >
              {iconName &&
                selectIcon(errors && errors[nameAttribute], disabled)}
              <Controller
                control={control}
                rules={rules || { required: true, minLength: 10 }}
                type={inputType || "phone"}
                name={nameAttribute}
                id={idAttribute}
                defaultValue={defaultValue || ""}
                className={`${
                  errors && errors[nameAttribute] ? "errors" : undefined
                }
                    ${iconName && "icon"}
                    `}
                iconName={iconName}
                render={({ field }) => (
                  <Cleave
                    options={
                      cleaveOptions || {
                        phone: true,
                        phoneRegionCode: "us",
                        delimiter: "-",
                      }
                    }
                    placeholder={placeholder || "Phone Number"}
                    className={` ${
                      errors && errors[name] ? "errors" : undefined
                    }
                    ${iconName && "icon"}
        
                    `}
                    {...field}
                  />
                )}
              />
              <label htmlFor={idAttribute}>
                {labelText || placeholder || "Phone number"}
              </label>
              {errors && errors[nameAttribute] && (
                <ErrorMessage>
                  {errorMessage ||
                    `Please enter a valid phone number with the area code.`}
                </ErrorMessage>
              )}
            </InputGroup>
          </>
        )
      } else if (type === "pass") {
        return (
          <Rosie
            title="pass"
            name="pass"
            autoComplete="off"
            tabIndex="-1"
            {...register("pass")}
          />
        )
      } else {
        return (
          <>
            <InputGroup
              className={`${iconName && "icon"} ${className} outlined-field`}
              iconPlacement={iconPlacement}
              showLabel={showLabel}
              isFieldEmpty={(watch && watch(name) === "") || !watch(name)}
            >
              {iconName && selectIcon(errors && errors[name], disabled)}
              {cleaveOptions ? (
                <Controller
                  type={inputType || "text"}
                  name={name}
                  control={control}
                  id={id || name}
                  defaultValue={defaultValue || ""}
                  ref={ref}
                  rules={rules}
                  render={({ field }) => (
                    <Cleave
                      options={cleaveOptions}
                      placeholder={placeholder}
                      {...field}
                      disabled={disabled}
                      className={` ${
                        errors && errors[name] ? "errors" : undefined
                      }
                      ${iconName && "icon"}
          
                      `}
                    />
                  )}
                />
              ) : (
                <input
                  type={inputType || "text"}
                  name={name}
                  id={id || name}
                  placeholder={placeholder}
                  defaultValue={defaultValue || ""}
                  ref={ref}
                  {...register(name, rules)}
                  className={`${errors && errors[name] ? "errors" : undefined}
                  ${iconName && "icon"}
      
                  `}
                  disabled={disabled}
                />
              )}
              <label htmlFor={id}>{labelText || placeholder}</label>

              {errors && errors[name] && (
                <ErrorMessage>
                  {errorMessage || `This doesn't seem quite right.`}
                </ErrorMessage>
              )}
            </InputGroup>
          </>
        )
      }
    } else if (field === "select") {
      if (type === "state") {
        idAttribute = id || "state"
        nameAttribute = name || "state"
        return (
          <>
            <SelectGroup
              className={`outlined-field ${className}`}
              isSelectFocused={isSelectFocused}
              showLabel={showLabel}
              isFieldEmpty={
                (watch && watch(nameAttribute) === "") || !watch(nameAttribute)
              }
            >
              <Controller
                control={control}
                rules={rules || { required: true, minLength: 12 }}
                name={nameAttribute}
                id={idAttribute}
                defaultValue={defaultValue || ""}
                className={`${
                  errors && errors[nameAttribute] ? "errors" : undefined
                }
                    ${iconName && "icon"}
                    `}
                render={({ field }) => (
                  <Select
                    className={`select ${
                      errors && errors[nameAttribute] ? "errors" : undefined
                    }
                        ${iconName && "icon"}
                        `}
                    classNamePrefix={"select"}
                    isSearchable={isSearchable}
                    onMenuClose={() => {
                      setIsSelectFocused(false)
                    }}
                    onMenuOpen={() => setIsSelectFocused(true)}
                    options={selectOptions.map(({ value, text }) => {
                      return {
                        value,
                        label: text,
                      }
                    })}
                    components={{
                      DropdownIndicator: ({ innerProps, isDisabled }) => (
                        <Icon
                          icon={iconName || "chevron-down"}
                          color={
                            isDisabled
                              ? colors["grey-3"].hex
                              : colors["dusky-grey-6"].hex
                          }
                          title={""}
                        />
                      ),
                    }}
                    placeholder={defaultValue || "State"}
                    styles={{
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: state.isDisabled
                          ? colors["grey-3"].hex
                          : colors["dusky-grey-6"].hex,
                        fontSize: "16px",
                        lineHeight: "20px",
                        margin: 0,
                        opacity: isSelectFocused ? 0 : 1,
                      }),
                    }}
                    isDisabled={disabled}
                    {...field}
                  />
                )}
              />

              <label htmlFor={idAttribute}>
                {labelText || placeholder || "State"}
              </label>
              {errors && errors[nameAttribute] && (
                <ErrorMessage>
                  {errorMessage || `Please select a state.`}
                </ErrorMessage>
              )}
            </SelectGroup>
          </>
        )
      } else {
        return (
          <>
            <SelectGroup
              className={`outlined-field ${className}`}
              isSelectFocused={isSelectFocused}
              showLabel={showLabel}
              isFieldEmpty={(watch && watch(name) === "") || !watch(name)}
            >
              <Controller
                control={control}
                rules={rules || { required: true, minLength: 12 }}
                name={name}
                id={id || name}
                defaultValue={defaultValue}
                className={`${errors && errors[name] ? "errors" : undefined}
                    ${iconName && "icon"}
                    `}
                render={({ field }) => (
                  <Select
                    className={`select ${
                      errors && errors[name] ? "errors" : undefined
                    }
                        ${iconName && "icon"}
                        `}
                    classNamePrefix={"select"}
                    isSearchable={isSearchable}
                    onMenuClose={() => {
                      setIsSelectFocused(false)
                    }}
                    onMenuOpen={() => setIsSelectFocused(true)}
                    options={selectOptions.map(({ value, text }) => {
                      return {
                        value,
                        label: text,
                      }
                    })}
                    placeholder={defaultValue}
                    styles={{
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: state.isDisabled
                          ? colors["grey-3"].hex
                          : colors["dusky-grey-6"].hex,
                        fontSize: "16px",
                        lineHeight: "20px",
                        margin: 0,
                        opacity: isSelectFocused ? 0 : 1,
                      }),
                    }}
                    isDisabled={disabled}
                    {...field}
                  />
                )}
              />

              <label htmlFor={id}>{labelText || placeholder}</label>
              {errors && errors[name] && (
                <ErrorMessage>
                  {errorMessage || `Please make a selection.`}
                </ErrorMessage>
              )}
            </SelectGroup>
          </>
        )
      }
    } else if (field === "textarea") {
      idAttribute = id || "message"
      nameAttribute = name || "message"

      if (type === "message") {
        return (
          <>
            <TextAreaGroup
              className={`outlined-field ${className}`}
              showLabel={showLabel}
              isFieldEmpty={
                (watch && watch(nameAttribute) === "") || !watch(nameAttribute)
              }
            >
              <textarea
                name={nameAttribute}
                id={idAttribute}
                cols={cols}
                rows={rows}
                placeholder={placeholder || `You can drop your questions here.`}
                className={
                  errors && errors[nameAttribute] ? "errors" : undefined
                }
                ref={ref}
                {...register(
                  nameAttribute,
                  rules || {
                    required: true,
                    minLength: 2,
                    maxLength: characterLimit,
                  }
                )}
                disabled={disabled}
              ></textarea>
              <label htmlFor={idAttribute}>
                {labelText || placeholder || "Message"}
              </label>
              <div
                className={`message-container ${
                  errors && errors[nameAttribute] ? "errors" : undefined
                }`}
              >
                {errors && errors[nameAttribute] && (
                  <ErrorMessage className="message-error">
                    {characterLimit &&
                    watch(nameAttribute).length > characterLimit
                      ? `You have exceeded the maximum characters.`
                      : errorMessage || `Please enter a longer message.`}
                  </ErrorMessage>
                )}
                {characterLimit && (
                  <div
                    className={`character-limit ${
                      errors && errors[nameAttribute] ? "errors" : undefined
                    }`}
                  >
                    {watch(nameAttribute) ? watch(nameAttribute).length : 0}/
                    {characterLimit}
                  </div>
                )}
              </div>
            </TextAreaGroup>
          </>
        )
      } else {
        return (
          <>
            <TextAreaGroup
              className={`outlined-field ${className}`}
              showLabel={showLabel}
              isFieldEmpty={(watch && watch(name) === "") || !watch(name)}
            >
              <textarea
                name={name}
                id={id || name}
                cols={cols}
                rows={rows}
                placeholder={placeholder}
                className={errors && errors[name] ? "errors" : undefined}
                ref={ref}
                {...register(
                  name,
                  rules || {
                    required: true,
                    minLength: 2,
                    maxLength: characterLimit,
                  }
                )}
                disabled={disabled}
              ></textarea>
              <label htmlFor={id}>{labelText || placeholder}</label>
              <div
                className={`message-container ${
                  errors && errors[name] ? "errors" : undefined
                }`}
              >
                {errors && errors[name] && (
                  <ErrorMessage className="message-error">
                    {characterLimit && watch(name).length > characterLimit
                      ? `You have exceed the maximum characters.`
                      : errorMessage || `Please enter a longer message.`}
                  </ErrorMessage>
                )}
                {characterLimit && (
                  <div
                    className={`character-limit ${
                      errors && errors[name] ? "errors" : undefined
                    }`}
                  >
                    {watch(name) ? watch(name).length : 0}/{characterLimit}
                  </div>
                )}
              </div>
            </TextAreaGroup>
          </>
        )
      }
    } else if (field === "radio") {
      return (
        <RadioGroup className={`outlined-field ${className}`}>
          <RadioInput
            name={name}
            aria-label={ariaLabel || id}
            type="radio"
            value={value}
            id={id || name}
            ref={ref}
            {...register(name)}
            disabled={disabled}
          />
          <RadioLabel htmlFor={id}>{labelText}</RadioLabel>
        </RadioGroup>
      )
    } else if (field === "checkbox") {
      return (
        <CheckboxGroup className={`outlined-field ${className}`}>
          <CheckboxInput
            name={name}
            aria-label={ariaLabel || id}
            type="checkbox"
            value={value}
            id={id || name}
            ref={ref}
            {...register(name)}
            disabled={disabled}
          />
          <RadioLabel htmlFor={id}>{labelText}</RadioLabel>
        </CheckboxGroup>
      )
    }
  }
  return <>{outlinedField(type)}</>
}

// Prop Types

OutlinedField.propTypes = {
  /**
   * Type of field
   */
  field: PropTypes.oneOf(["input", "select", "textarea", "radio", "checkbox"]),
  /**
   * Form errors
   */
  errors: PropTypes.shape(),
  /**
   * Type of field (e.g. phone, name, email)
   */
  type: PropTypes.string,
  /**
   * Name attribute
   */
  name: PropTypes.string,
  /**
   * Id attribute
   */
  id: PropTypes.string,
  /**
   * Placeholder attribute
   */
  placeholder: PropTypes.string,
  /**
   * Default value of field
   */
  defaultValue: PropTypes.string,
  /**
   * Ref value
   */
  ref: PropTypes.any,
  /**
   * Class name of field
   */
  className: PropTypes.string,
  /**
   * Text of field label
   */
  labelText: PropTypes.string,
  /**
   * Error message
   */
  errorMessage: PropTypes.string,
  /**
   * Register function from useForm hook (react-hook-form)
   */
  register: PropTypes.func,
  /**
   * Type of input (e.g. text, tel, email)
   */
  inputType: PropTypes.string,
  /**
   * Control prop for Controller component from useForm hook (react-hook-form)
   */
  control: PropTypes.shape(),
  /**
   * Controller component (handles registration process) from react-hook-form
   */
  Controller: PropTypes.func,
  /**
   * Rules prop for Controller component from useForm hook (react-hook-form)
   * e.g. {required: true}
   */
  rules: PropTypes.shape(),
  /**
   * Options for select element
   * e.g. <option value="value">Value</option>
   */
  selectOptions: PropTypes.array,
  /**
   * Cols (width) attribute for textarea
   */
  cols: PropTypes.number,
  /**
   * Rows (# of lines) attribute for textarea
   */
  rows: PropTypes.number,
  /**
   * Aria label (accessibility) attribute
   */
  ariaLabel: PropTypes.string,
  /**
   * Value of field
   */
  value: PropTypes.string,
  /** Name of icon (Generic Icon) */
  iconName: PropTypes.string,
  /** Where should icon be placed inside of field? (left or right) */
  iconPlacement: PropTypes.oneOf(["left", "right"]),
  /** Functions to be used on icon (e.g. onClick, onMouseEnter) */
  iconFunctions: PropTypes.shape(),
  /** Title attribute for icon (shows when hovering over icon) */
  iconTitle: PropTypes.string,
  /** Is field disabled? */
  disabled: PropTypes.bool,
  /** Max # of characters field can have */
  characterLimit: PropTypes.number,
  /**
   * Function that gets current value of field from react-hook-form
   */
  watch: PropTypes.func,
  /** Should select field be searchable? */
  isSearchable: PropTypes.bool,
  /** Type of options to format input (e.g. { phone: true, delimiter: "-"}) */
  cleaveOptions: PropTypes.shape(),
  /** Should the label be displayed or not? */
  showLabel: PropTypes.bool,
}

OutlinedField.defaultProps = {
  characterLimit: 250,
  field: "input",
  iconPlacement: "left",
  isSearchable: true,
  showLabel: true,
}
