// Packages
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// Components
import { Link as GatsbyLink } from "gatsby"

// Utils
import { getMediaQueries, colors } from "@gray-fox-components/utils/utils"

// Styles
import "@gray-fox-components/utils/_variables.css"
const { canHover } = getMediaQueries()

const StyledLink = styled.a`
  background: transparent;
  color: ${props => props.$defaultColor || colors["pilot-grey-7"].hex};
  display: flex;
  max-width: 100%;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  .text {
    color: ${props => props.$defaultColor || colors["pilot-grey-7"].hex};
    flex-shrink: 0;
  }
  svg {
    flex-shrink: 0;
    transform: translateX(0);
    path {
      stroke: ${props => props.$defaultColor || colors["pilot-grey-7"].hex};
    }
    transition: all 0.3s ease-in-out;
  }
  ${canHover`
    &:hover {
      text-decoration: none;
      svg {
        transform: translateX(10px);
        path {
          stroke: ${props => props.$activeColor || props.$defaultColor};
        }
      }
      .text {
        color: ${props => props.$activeColor || props.$defaultColor};
      }
    }
  `}
`

const StyledLinkContentsContainer = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`

function Link(props) {
  return (
    <>
      <StyledLink
        {...props.linkAttributes}
        $activeColor={props.activeColor}
        $defaultColor={props.defaultColor}
        as={
          props.linkAttributes && props.linkAttributes.href ? "a" : GatsbyLink
        }
        className={props.className}
      >
        <StyledLinkContentsContainer>
          <span className="text layout__body--regular">{props.text}</span>
          {props.image && props.image}
          {props.icon && props.icon}
        </StyledLinkContentsContainer>
      </StyledLink>
    </>
  )
}

export default Link

Link.propTypes = {
  /**
   * Text color of link when link is active (clicked / hovered / pressed)
   */
  activeColor: PropTypes.string,
  /**
   * StyledLink text
   */
  text: PropTypes.string.isRequired,
  /**
   * Default text color of link
   */
  defaultColor: PropTypes.string,
  /**
   * Image should be a Generic Icon
   */
  image: PropTypes.element,
  /**
   * Button icon (should be GenericIcon)
   */
  icon: PropTypes.element,
  /**
   * StyledLink Attributes e.g. { to: "/", target: "_blank"}
   */
  linkAttributes: PropTypes.shape(),
  /** Class name of link */
  className: PropTypes.string,
}

Link.defaultProps = {
  defaultColor: "#4A7AAE",
}
